import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getInvoice, getInvoiceData, getInvoiceDetails } from "../../store/invoices";
import queryString from "query-string";
import { useLocation } from "react-router";
import HTMLReactParser from "html-react-parser";
import print from "../../include/images/print.svg";
import moment from "moment/moment";
import "../../include/css/invoice.css";
import { Helmet } from "react-helmet";

const ViewInvoice = (props) => {
  const location = useLocation();
  const [result, setResult] = useState("");
  const [date, setDate] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [printData, setPrintData] = useState("");

  useEffect(() => {
    document.body.classList.add("p-0");

    return () => document.body.classList.remove("p-0");
  }, []);

  useEffect(() => {
    const auctionId = queryString.parse(location.search).auctionId;
    const d = queryString.parse(location.search).date;
    const invNum = queryString.parse(location.search)?.inv_num ? queryString.parse(location.search)?.inv_num : "";
    const bId = queryString.parse(location.search).b_id ? queryString.parse(location.search).b_id : "";
    if (invNum) {
      setInvoiceId(invNum);
    } else {
      props.getInvoiceData(auctionId, bId, (res) => {
        if (res.status === 200) {
          const data = res?.data?.data;
          setInvoiceId(data?.auction_id ? data?.auction_id + "-" + data?.b_id + (data?.icount - 1) : "");
        }
      });
    }
    setDate(d);
    const id = queryString.parse(location.search).id;
    props.getInvoiceDetails(auctionId, id, (res) => {
      if (res && res.status == 200) {
        const modifiedHtml = res.data?.data?.details.replace(
          "<h3",
          `<button id="print-button" type="button" class="btn btn-default mb-2">
            <img class="asc-icon" src="${print}" alt="" />
            <span class="ms-3">Print PDF</span>
          </button><h3`,
        );
        setResult(modifiedHtml);
        setPrintData(res.data?.data?.details);
      }
    });
  }, []);

  const printHandler = (data) => {
    let pri =
      document.getElementById("ifmcontentstoprintinvoice") &&
      document.getElementById("ifmcontentstoprintinvoice").contentWindow;
    if (pri) {
      pri.document.open();
      pri.document.write(data);
      pri.document.close();
      pri.focus();
      setTimeout(() => pri.print(), 100);
    }
  };

  useEffect(() => {
    if (printData) {
      const printButton = document.getElementById("print-button");
      if (printButton) {
        printButton.onclick = () => {
          printHandler(printData);
        };
      }
    }
  }, [printData]);

  return (
    <>
      <Helmet>
        <title>
          {invoiceId ? (invoiceId !== "undefined" ? invoiceId : "Salvex - Admin Panel") : "Salvex - Admin Panel"}
        </title>
      </Helmet>
      <iframe id="ifmcontentstoprintinvoice" style={{ height: "0p", width: "0px", position: "absolute" }}></iframe>
      {date && moment("2023/06/06").diff(moment(date)) < 0 ? (
        <div className="create-invoice">
          {" "}
          {!result?.includes("<h3") && result && (
            <button type="button" class="btn btn-default" onClick={() => printHandler(result)}>
              <img class="asc-icon" src={print} alt="" />
              <span className="ms-3">Print PDF</span>
            </button>
          )}
          {HTMLReactParser(result)}
        </div>
      ) : date ? (
        <span className="invoice-view invoice-fix create-invoice">
          {!result?.includes("<h3") && result && (
            <button type="button" class="btn btn-default" onClick={() => printHandler(result)}>
              <img class="asc-icon" src={print} alt="" />
              <span className="ms-3">Print PDF</span>
            </button>
          )}
          {HTMLReactParser(result)}
        </span>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getInvoiceDetails: (auctionId, id, callback) => dispatch(getInvoiceDetails(auctionId, id, callback)),
  getInvoiceData: (params, bidId, callback) => dispatch(getInvoiceData(params, bidId, callback)),
});

const mapStateToProps = (state) => ({
  getInvoice: getInvoice(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ViewInvoice));
