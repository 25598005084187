import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import Header from "../layouts/header";
import FilterModal from "../layouts/modals/filterModal";
import Table from "../components/searchTable/table";
import { getListings, searchListings, listingsReceived } from "../store/listings";
import { checkToken, setRedirectUrl } from "../utils.js/localStorageServices";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import AlertError from "../common/alerts/alertError";
import { toast } from "react-toastify";

//Images
import plusIcon from "../include/images/plus-icon.svg";
import { getCountries, getStates, productLocationDetails } from "../store/productLocation";
import Sidebar from "../layouts/sidebar";

const ListingTable = (props) => {
  const history = useHistory();
  var ref = useRef(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [tableOpened, setTableOpened] = useState(false);
  const [itemId, setItemId] = useState("");
  const [serachItem, setSearchItem] = useState("");
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const location = useLocation();
  var searchId = location && location.state && location.state.search;

  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");

  useEffect(() => {
    if (!checkToken()) {
      ref && ref.current && ref.current.complete();
      const a = window.location.href;
      setRedirectUrl(a);
      props.history.push("/signin");
    }
  }, []);

  useEffect(() => {
    if (searchId && !loading) {
      setLoading(true);
      ref.current.continuousStart();
      props.searchListings({ q: searchId }, (res) => {
        if (res && res.status === 200) {
          setLoading(false);
          setNotFound(false);
          ref.current && ref.current.complete();
        } else if (res && res.status === 401) {
          setLoading(false);
          ref.current.complete();

          history.replace("/signin");
        } else {
          setLoading(false);
          setNotFound(true);
          ref.current && ref.current.complete();
          props.listingsReceived([]);
        }
      });
    }
  }, [searchId]);

  useEffect(() => {
    if (!props?.productLocationDetails?.states?.data) {
      props.getStates((res) => {
        if (res.status === 200) {
          ref.current && ref.current.complete();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!props?.productLocationDetails?.countries?.data) {
      props.getCountries((res) => {
        if (res.status === 200) {
          ref.current && ref.current.complete();
        }
      });
    }
  }, []);

  const searchHandler = () => {
    if (serachItem && !loading) {
      setLoading(true);
      ref.current.continuousStart();
      props.searchListings({ q: serachItem }, (res) => {
        if (res && res.status === 200) {
          setLoading(false);
          setNotFound(false);
          ref.current && ref.current.complete();
        } else if (res && res.status === 400) {
          setLoading(false);
          ref.current.complete();

          history.replace("/signin");
        } else {
          setLoading(false);
          setNotFound(true);
          ref.current && ref.current.complete();
          props.listingsReceived([]);
        }
      });
    }
  };

  const listingsData = props.getSearchListings && props.getSearchListings.listings;

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <div class="dashboard-insider position-relative d-lg-flex w-100 ">
        <Sidebar
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          setMenuList={setMenuList}
          menuList={menuList}
          page="Listings"
        />
        <div className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}>
          <div class="filter-container d-flex align-items-center sticky-top">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 d-flex align-items-center flex-wrap justify-content-between">
                  <div class="filter-title fw-medium text-capitalize">All Listings</div>
                  <div class="filter-widget-right d-flex align-items-center flex-wrap">
                    <div class="fw-item">
                      {/* <div
                    class="filter-row d-flex align-items-center"
                    onClick={() => setShowFilterModal(true)}
                  >
                    <svg
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.39767 16.5C2.07751 16.5 1.81796 16.2357 1.81796 15.9097V1.09032C1.81796 0.764295 2.07751 0.5 2.39767 0.5C2.71784 0.5 2.97738 0.764295 2.97738 1.09032V15.9097C2.97738 16.2357 2.71784 16.5 2.39767 16.5Z"
                        fill="currentColor"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.0226 15.9097C13.0226 16.2357 13.2822 16.5 13.6023 16.5C13.9225 16.5 14.182 16.2357 14.182 15.9097V1.09032C14.182 0.764295 13.9225 0.5 13.6023 0.5C13.2822 0.5 13.0226 0.764295 13.0226 1.09032V15.9097ZM0.57971 13.18H4.21565C4.53582 13.18 4.79536 12.9158 4.79536 12.5897C4.79536 12.2637 4.53582 11.9994 4.21565 11.9994H0.57971C0.259545 11.9994 0 12.2637 0 12.5897C0 12.9158 0.259545 13.18 0.57971 13.18Z"
                        fill="currentColor"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.42029 15.9097C7.42029 16.2357 7.67983 16.5 8 16.5C8.32016 16.5 8.57971 16.2357 8.57971 15.9097V1.09032C8.57971 0.764295 8.32016 0.5 8 0.5C7.67983 0.5 7.42029 0.764295 7.42029 1.09032V15.9097ZM11.7843 13.18H15.4203C15.7405 13.18 16 12.9158 16 12.5897C16 12.2637 15.7405 11.9994 15.4203 11.9994H11.7843C11.4642 11.9994 11.2046 12.2637 11.2046 12.5897C11.2046 12.9158 11.4642 13.18 11.7843 13.18Z"
                        fill="currentColor"
                      />
                      <path
                        d="M9.81798 5.00058H6.18204C5.86187 5.00058 5.60233 4.73629 5.60233 4.41027C5.60233 4.08424 5.86187 3.81995 6.18204 3.81995H9.81798C10.1381 3.81995 10.3977 4.08424 10.3977 4.41027C10.3977 4.73629 10.1381 5.00058 9.81798 5.00058Z"
                        fill="currentColor"
                      />
                    </svg>
                    <span>Filters</span>
                  </div> */}
                    </div>
                    {/* <div class="fw-item">
                  <a href="javascript:void(0)" class="btn btn-default">
                    <img src={plusIcon} alt="" />
                    Add a Listing
                  </a>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Table
            listingsData={listingsData}
            tableOpened={tableOpened}
            setTableOpened={setTableOpened}
            itemId={itemId}
            setItemId={setItemId}
            searchItem={serachItem}
            setSearchItem={setSearchItem}
            searchHandler={searchHandler}
            searchId={searchId}
            loading={loading}
            notFound={notFound}
          />

          <FilterModal showFilterModal={showFilterModal} setShowFilterModal={setShowFilterModal} />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  searchListings: (params, callback) => dispatch(searchListings(params, callback)),
  getStates: (callback) => dispatch(getStates(callback)),
  getCountries: (callback) => dispatch(getCountries(callback)),

  //global  state to change data
  listingsReceived: (params, callback) => dispatch(listingsReceived(params, callback)),
});
const mapStateToProps = (state) => ({
  getSearchListings: getListings(state),
  productLocationDetails: productLocationDetails(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(React.memo(ListingTable)));
