import React, { useEffect, useState } from "react";
import {
  getInvoice,
  getInvoiceList,
  inspectionWaiver,
  invoiceAddNote,
  updateDepositPaid,
  updateInvoiceStatus,
} from "../../../store/invoices";
import { connect } from "react-redux";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import DeActivateRateModal from "../../../layouts/modals/invoicesModal/deActivateRateModal";
import CollectModal from "../../../layouts/modals/invoicesModal/collectModal";
import { updateCollect } from "../../../store/invoices";
import MarkPaidModal from "../../../layouts/modals/invoicesModal/markPaidModal";
import { getFeedback } from "../../../store/invoices";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import ResendModal from "../../../layouts/modals/invoicesModal/resendModal";
import ReactivateModal from "../../../layouts/modals/invoicesModal/reactivateModal";
import DeactivateModal from "../../../layouts/modals/invoicesModal/deactivateModal";
import helpCircle from "../../../include/images/help-circle.svg";

import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";

import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import AddNotesModal from "../../../layouts/modals/invoicesModal/addNotesModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import AuthorizationPayModal from "../../../layouts/modals/invoicesModal/authorizationPayModal";
import ReactTooltip from "react-tooltip";
import { getBids } from "../../../store/bids";

const Invoices = (props) => {
  const val = localStorage.getItem("data");
  const session = JSON.parse(val);
  const count = props?.count?.invoice;
  const [showDeactivateRateModal, setShowDeactivateRateModal] = useState(false);
  const [showMarkPaidModal, setShowMarkPaidModal] = useState(false);
  const [collectData, setCollectData] = useState({ send_collection_notice: 0 });
  const [showCollectModal, setShowCollectModal] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);
  const [noticeDate, setNoticeDate] = useState("");
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false);
  const [inspectionRes, setInspectionRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [Params, setParams] = useState({
    invoice_id: 0,
    action: "",
  });
  const [statusData, setStatusData] = useState({
    invoice_id: 0,
    action: "",

    posted_by: 0,
    member_id: 0,
    buyer_id: 0,
    rating: 0,
    review: "",
  });
  const [inspectionWaiver, setInspectionWaiver] = useState({
    inv_id: 0,
    member_id: 0,
    buyer_id: 0,
  });
  const [invoiceNote, setInvoiceNote] = useState({
    invoice_id: 0,
    invoiceNotePrompt: false,
    invoiceNote: "",
  });
  const [showInspectionAlert, setShowInspectionAlert] = useState(false);

  const [statusStaticData, setStatusStaticData] = useState({});

  const [showAddNote, setShowAddNote] = useState(false);
  const invoiceData = props && props.invoices && props.invoices.invoices && props.invoices.invoices.data;
  const addInvoice = props?.invoices?.addInvoice?.data;
  const userAccess = props?.invoices?.userAccess?.data;

  const listFindNoCase = (list, value) => {
    const listArray = list.split(",").map((item) => item.trim().toLowerCase());
    if (value) {
      const lowercasedValue = value && value.trim().toLowerCase();
      const index = listArray.indexOf(lowercasedValue);
      return index !== -1 ? index + 1 : 0;
    } else {
      return 0;
    }
  };

  const allowedJobRoles = "Management,Accounting,Legal";

  const toggleAuthorizationModal = () => {
    setShowAuthorizationModal(!showAuthorizationModal);
  };

  const toggleDeactivateModal = () => {
    setShowDeactivateRateModal(!showDeactivateRateModal);
  };
  const toggleMarkPaidModal = () => {
    setShowMarkPaidModal(!showMarkPaidModal);
  };

  const toggleCollectModal = () => {
    setShowCollectModal(!showCollectModal);
  };
  const toggleResendModal = () => {
    setShowResendModal(!showResendModal);
  };
  const toggelReactivateModal = () => {
    setShowReactivateModal(!showReactivateModal);
  };
  const toggelDeactivatedModal = () => {
    setShowDeactivateModal(!showDeactivateModal);
  };
  const toggleAddNote = () => {
    setShowAddNote(!showAddNote);
  };

  const updateStatus = (id, datas) => {
    setLoading(true);
    props.refStart();
    props.updateInvoiceStatus(id, datas, (res) => {
      if (res.status === 200) {
        props.getInvoiceList(props.auctionId, (res) => {
          if (res.status === 200) {
            props.getBids(props.auctionId, (res) => {});
            setLoading(false);
            props.refStop();
            if (datas.action === "resend") {
              toggleResendModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            } else if (datas.action === "reactivate") {
              toggelReactivateModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            } else if (datas.action === "deactivate") {
              toggelDeactivatedModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            }
          } else {
            setLoading(false);
            props.refStop();
            if (datas.action === "resend") {
              toggleResendModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            } else if (datas.action === "reactivate") {
              toggelReactivateModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            } else if (datas.action === "deactivate") {
              toggelDeactivatedModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            }
          }
        });
      } else {
        setLoading(false);
        props.refStop();
        toast(<AlertError message={"Something Went Wrong"} />);
      }
    });
  };

  const updateDepositPaid = (id) => {
    const payload = {
      invoice_id: id,
    };
    setLoading(true);
    props.refStart();
    props.updateDepositPaid(props.auctionId, payload, (res) => {
      if (res.status === 200) {
        props.getInvoiceList(props.auctionId, (res) => {
          if (res.status === 200) {
            props.getBids(props.auctionId, (res) => {});
            setLoading(false);
            props.refStop();
          } else {
            setLoading(false);
            props.refStop();
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
          }
        });
      } else {
        setLoading(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const collectUpdate = () => {
    setLoading(true);
    props.refStart();
    props.updateCollect(props.auctionId, collectData, (res) => {
      if (res.status === 200) {
        props.getInvoiceList(props.auctionId, (res) => {
          if (res.status === 200) {
            props.getBids(props.auctionId, (res) => {});
            setLoading(false);
            props.refStop();
            toggleCollectModal();
          } else {
            setLoading(false);
            props.refStop();
            toggleCollectModal();
          }
        });
      } else {
        setLoading(false);
        props.refStop();
        toast(<AlertError message={"Something Went Wrong"} />);
      }
    });
  };

  const openMarkPaidModal = (invoiceId, action) => {
    props.refStart();
    props.getFeedback(props.auctionId, invoiceId, action, (res) => {
      if (res.status === 200) {
        const value = res.data.data;
        setStatusData({
          invoice_id: invoiceId ? invoiceId : 0,
          action: action ? action : "",

          posted_by: value.posted_by ? value.posted_by : 0,
          member_id: value.member_id ? value.member_id : 0,
          buyer_id: value.buyer_id ? value.buyer_id : 0,
          rating: value.rating ? value.rating : 0,
          review: value.review ? value.review : "",
        });
        setStatusStaticData(value);
        props.refStop();
        toggleMarkPaidModal();
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const openDeactivateRateModal = (invoiceId, action) => {
    props.refStart();
    props.getFeedback(props.auctionId, invoiceId, action, (res) => {
      if (res.status === 200) {
        const value = res.data.data;
        setStatusData({
          invoice_id: invoiceId ? invoiceId : 0,
          action: action ? action : "",

          posted_by: value.posted_by ? value.posted_by : 0,
          member_id: value.member_id ? value.member_id : 0,
          buyer_id: value.buyer_id ? value.buyer_id : 0,
          rating: value.rating ? value.rating : 0,
          review: value.review ? value.review : "",
        });
        setStatusStaticData(value);
        props.refStop();
        toggleDeactivateModal();
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const invoiceStatusUpdate = () => {
    setLoading(true);
    props.refStart();
    props.updateInvoiceStatus(props.auctionId, statusData, (res) => {
      if (res.status === 200) {
        props.getInvoiceList(props.auctionId, (res) => {
          if (res.status === 200) {
            props.getBids(props.auctionId, (res) => {});
            setLoading(false);
            props.refStop();
            if (statusData.action === "markpaid") {
              toggleMarkPaidModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            } else if (statusData.action === "deactivate") {
              toggleDeactivateModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            }
          } else {
            setLoading(false);
            props.refStop();
            if (statusData.action === "markpaid") {
              toggleMarkPaidModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            } else if (statusData.action === "deactivate") {
              toggleDeactivateModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            }
          }
        });
      } else {
        setLoading(false);
        props.refStop();
        toast(<AlertError message={"Something Went Wrong"} />);
      }
    });
  };

  //Handle Inspection Waiver
  const handleInspectionWaiver = () => {
    setLoading(true);
    props.refStart();
    props.inspectionWaiver(props.auctionId, inspectionWaiver, (res) => {
      if (res.status === 200) {
        props.refStop();
        setLoading(false);
        toggleAuthorizationModal();
        props.getInvoiceList(props.auctionId, (res) => {});
        props.getBids(props.auctionId, (res) => {});
        setInspectionRes(true);
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Add Note
  const handleAddNote = () => {
    setLoading(true);
    props.refStart();
    props.invoiceAddNote(props.auctionId, invoiceNote, (res) => {
      if (res.status === 200) {
        const detail = res?.data?.data ? res?.data?.data : {};
        const data = { ...invoiceNote };
        data.invoiceNotePrompt = false;
        data.invoiceNote = detail.invoice_notes ? detail.invoice_notes : "";
        setInvoiceNote(data);
        props.refStop();
        setLoading(false);
        toggleAddNote();
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (invoiceNote.invoiceNotePrompt) {
      handleAddNote();
    }
  }, [invoiceNote]);

  return (
    <div class="details-page-widget-block" id="invoiceRef">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize">
            Invoices {!props?.loadingMain && (count > 0 ? `(${count})` : "(0)")}
          </div>
        </div>
        {/* {userAccess?.length &&
        userAccess[0]?.is_salvex_team_member &&
        listFindNoCase(allowedJobRoles, userAccess[0]?.job_role) ? (
          <div class="btn-block">
            <a href={`/invoice/create-invoice?id=${props.auctionId}`} target="_blank" class="btn btn-primary btn-sm">
              Add New
            </a>
          </div>
        ) : null} */}
      </div>
      <div class={invoiceData?.length > 0 && "page-widget-body mt-3"}>
        <div class="table-responsive">
          {props.loadingMain ? (
            <table class="large-table align-left">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>Invoice</th>
                  <th>Buyer</th>
                  <th>Email</th>
                  <th>Location</th>
                  <th>Created</th>
                  <th>Last Sent</th>
                  <th>Deposit</th>
                  <th>Notice Sent</th>
                  <th>Viewed</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4, 5]?.map((val) => (
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            invoiceData?.length > 0 && (
              <table class="large-table align-left">
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col style={{ width: "28px" }} />
                  <col style={{ width: "28px" }} />
                  <col style={{ width: "28px" }} />
                  <col style={{ width: "28px" }} />
                  <col style={{ width: "28px" }} />
                  <col style={{ width: "28px" }} />
                  <col style={{ width: "28px" }} />
                </colgroup>
                <thead>
                  <tr style={{ backgroundColor: "#CCCCFF" }}>
                    <th>Invoice</th>
                    <th>Buyer</th>
                    <th>Email</th>
                    <th>Location</th>
                    <th>Created</th>
                    <th>Last Sent</th>
                    <th>Deposit</th>
                    <th>Notice Sent</th>
                    <th>Viewed</th>
                    <th>Amount</th>
                    <th>Invoices Note</th>
                    {session?.accessLevels?.accounting_payment && <th></th>}
                    {session?.accessLevels?.accounting_payment && <th></th>}
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData?.map((val) => (
                    <tr
                      style={{
                        backgroundColor:
                          val.active && !val?.paid ? "white" : val.paid ? "darkseagreen" : !val?.active ? "pink" : "",
                      }}
                    >
                      <td>
                        <Link
                          to={`/invoice/view-invoice?auctionId=${props.auctionId}&id=${val.id}&date=${val.date_created}&inv_num=${val.inv_num}`}
                          state={{ a: "dk" }}
                          target="_blank"
                        >
                          {val.inv_num ? val.inv_num : "N/A"}
                        </Link>
                      </td>
                      <td>
                        <a
                          className="cursor-pointer"
                          onClick={() => {
                            if (val?.name) {
                              if (process.env.REACT_APP_MODE == "development") {
                                window.open(
                                  `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`,
                                );
                              } else {
                                window.open(`https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`);
                              }
                            }
                          }}
                        >
                          {val?.name ? val?.name : "N/A"}
                        </a>
                      </td>
                      <td>
                        {val.email ? (
                          <>
                            <a className="green-text" href={`mailto:${val.email}`}>
                              {val.email}
                            </a>
                            <div class="d-text-item">
                              {val?.emailstatuscode === 0 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>Email OK</span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={greenEmail} alt="" />
                                </OverlayTrigger>
                              ) : val?.emailstatuscode === 1 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)</span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={purpleEmail} alt="" />
                                </OverlayTrigger>
                              ) : val?.emailstatuscode === 2 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>Email Invalid</span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={invalid} alt="" />
                                </OverlayTrigger>
                              ) : val?.emailstatuscode === 3 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>Email Blocked by Spam or Firewall</span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={spamEmail} alt="" />
                                </OverlayTrigger>
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>Email Not Verified</span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={notVerified} alt="" />
                                </OverlayTrigger>
                              )}
                            </div>
                          </>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip style={{ position: "fixed" }}>
                                <span>Email Not Verified</span>
                              </Tooltip>
                            }
                          >
                            <img src={notVerified} alt="" title="" />
                          </OverlayTrigger>
                        )}
                      </td>
                      <td>{val.city || val.abb ? `${val.city} ${val?.abb ? ", " + val.abb : ""} ` : "N/A"}</td>
                      <td>{val.date_created ? <DateFormat date={val.date_created} /> : "N/A"}</td>
                      <td>{val.last_sent ? <DateFormat date={val.last_sent} /> : "N/A"}</td>
                      <td>{val.deposit ? "Yes" : "No"}</td>
                      <td>{val.notice ? "Yes" : "No"}</td>
                      <td>{val.viewed ? val.viewed : "N/A"}</td>
                      <td
                        className="cursor-pointer"
                        data-for={`inv-num-01-${val?.id}`}
                        data-tip={
                          val?.inspection_deposit
                            ? `
                      <span>Total Amount: ${
                        val?.total_amount
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                            }).format(val?.total_amount)
                          : "$0.00"
                      }</span> <br>
                      <span>(Credit): ${
                        val?.credit
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                            }).format(val?.credit)
                          : "$0.00"
                      }</span> <br>
                      <span>Deposit Due Now: ${
                        val?.inspection_deposit
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                            }).format(val?.inspection_deposit)
                          : "$0.00"
                      }</span> <br>
                      <span>Deposit Due Later: ${
                        val?.amount
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                            }).format(val?.amount)
                          : "$0.00"
                      }</span> <br>
                      `
                            : `
                            <span>Total Amount: ${
                              val?.total_amount
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                  }).format(val?.total_amount)
                                : "$0.00"
                            }</span> <br>
                      <span>(Credit): ${
                        val?.credit
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                            }).format(val?.credit)
                          : "$0.00"
                      }</span> <br>
                      <span>Sales Tax: ${
                        val?.sales_tax
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                            }).format(val?.sales_tax)
                          : "$0.00"
                      }</span> <br>
                      <span>Total Due: ${
                        val?.amount
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                            }).format(val?.amount)
                          : "$0.00"
                      }</span>
                      `
                        }
                      >
                        {val.amount
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                            }).format(val?.amount)
                          : "$0.00"}
                        <ReactTooltip
                          effect="solid"
                          place="top"
                          type="dark"
                          html={true}
                          border={true}
                          id={`inv-num-01-${val?.id}`}
                        />
                      </td>
                      <td>
                        <a
                          className="cursor-pointer"
                          onClick={() => {
                            const data = { ...invoiceNote };
                            data.invoice_id = val.id;
                            data.invoiceNotePrompt = true;
                            data.invoiceNote = "";
                            setInvoiceNote(data);
                          }}
                        >
                          Click here to Add/View
                        </a>
                      </td>
                      {session?.accessLevels?.accounting_payment && (
                        <td>
                          {val.active &&
                            (val.paid ? (
                              <button
                                className="btn btn-primary btn-xs-size btn-auto "
                                onClick={() =>
                                  updateStatus(props.auctionId, {
                                    invoice_id: val.id,
                                    action: "markunpaid",
                                  })
                                }
                              >
                                MARK UNPAID
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary btn-xs-size btn-auto "
                                onClick={() => {
                                  openMarkPaidModal(val.id, "markpaid");
                                }}
                              >
                                MARK PAID
                              </button>
                            ))}
                        </td>
                      )}
                      {session?.accessLevels?.accounting_payment && (
                        <td>
                          <button
                            className="btn btn-primary btn-xs-size btn-auto "
                            onClick={() => updateDepositPaid(val.id)}
                          >
                            DEPOSIT PAID
                          </button>
                        </td>
                      )}
                      <td>
                        {val.active &&
                          (val.inspection_waiver_btn ? (
                            val.btn_text && val?.btn_text?.split("")?.length > 0 ? (
                              <p>{val.btn_text}</p>
                            ) : (
                              <p>
                                {`Authorization to Pay Seller Form Submitted at ${moment(val.wavier_time).format(
                                  "MM/DD/YY",
                                )}`}{" "}
                                <br /> {val.waived_by}
                              </p>
                            )
                          ) : val?.paid &&
                            props.sellerVerificationStatus === "green" &&
                            props.payableCount > 0 &&
                            props.qtyConfirmed &&
                            props.locationFilled ? (
                            inspectionRes ? (
                              <p>
                                {`Authorization to Pay Seller Form Submitted at ${moment().format("MM/DD/YY")}`}
                                <br /> {session.name01}
                              </p>
                            ) : (
                              <button
                                className="btn btn-primary btn-xs-size btn-auto"
                                onClick={() => {
                                  const data = { ...inspectionWaiver };
                                  data.inv_id = val.id;
                                  data.buyer_id = val.buyer_id;
                                  data.member_id = val.member_id;
                                  setInspectionWaiver(data);
                                  toggleAuthorizationModal();
                                }}
                              >
                                Authorization to Pay Seller
                              </button>
                            )
                          ) : (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip style={{ position: "fixed", padding: "25px" }}>
                                  <span>
                                    You have not met requirements to use this tool. Requirements to send this form
                                    include the following:
                                    <br />
                                    1). Invoice must be marked 'paid'
                                    <br />
                                    2). Product location must have a complete address
                                    <br />
                                    3). Seller verification must be marked GREEN
                                    <br />
                                    4). A payable must be set up
                                    <br />
                                    5). Quantity must be confirmed and checked GREEN
                                  </span>
                                </Tooltip>
                              }
                            >
                              <button
                                className="btn btn-primary btn-xs-size btn-auto"
                                style={{
                                  cursor: "not-allowed",
                                  opacity: "0.4",
                                  color: "white",
                                }}
                              >
                                Authorization to Pay Seller
                              </button>
                            </OverlayTrigger>
                          ))}
                      </td>
                      <td>
                        {val.active &&
                          (val.refund ? (
                            <button
                              className="btn btn-primary btn-xs-size btn-auto"
                              onClick={() =>
                                updateStatus(props.auctionId, {
                                  invoice_id: val.id,
                                  action: "refunded",
                                })
                              }
                            >
                              REFUNDED
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary btn-xs-size btn-auto"
                              onClick={() =>
                                updateStatus(props.auctionId, {
                                  invoice_id: val.id,
                                  action: "markrefund",
                                })
                              }
                            >
                              REFUND
                            </button>
                          ))}
                      </td>
                      <td>
                        {val.active && (
                          <button
                            className="btn btn-primary btn-xs-size btn-auto"
                            onClick={() => {
                              setParams({
                                invoice_id: val.id,
                                action: "resend",
                              });
                              toggleResendModal();
                            }}
                          >
                            RESEND
                          </button>
                        )}
                      </td>
                      <td>
                        {val.active && (
                          <button
                            className="btn btn-primary btn-xs-size btn-auto"
                            onClick={() => {
                              setCollectData({
                                send_collection_notice: val.id,
                              });
                              setNoticeDate(val.notice_date);
                              toggleCollectModal();
                            }}
                          >
                            COLLECT
                          </button>
                        )}
                      </td>
                      <td>
                        {val.active && (
                          <button
                            className="btn btn-primary btn-xs-size btn-auto"
                            onClick={() => openDeactivateRateModal(val.id, "deactivate")}
                          >
                            DEACTIVATE AND RATE
                          </button>
                        )}
                      </td>
                      <td>
                        {val.active ? (
                          <button
                            className="btn btn-primary btn-xs-size btn-auto"
                            onClick={() => {
                              setParams({
                                invoice_id: val.id,
                                action: "deactivate",
                              });
                              toggelDeactivatedModal();
                            }}
                          >
                            DEACTIVATE
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-xs-size btn-auto"
                            onClick={() => {
                              setParams({
                                invoice_id: val.id,
                                action: "reactivate",
                              });
                              toggelReactivateModal();
                            }}
                          >
                            REACTIVATE
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )
          )}
        </div>
      </div>
      <AddNotesModal
        show={showAddNote}
        onHide={toggleAddNote}
        addInvoice={addInvoice}
        loading={loading}
        setInvoiceNote={setInvoiceNote}
        invoiceNote={invoiceNote}
        handleSubmit={handleAddNote}
      />
      <CollectModal
        show={showCollectModal}
        onHide={toggleCollectModal}
        loadingDelete={loading}
        date={noticeDate}
        submitHandler={collectUpdate}
      />
      <AuthorizationPayModal
        show={showAuthorizationModal}
        onHide={toggleAuthorizationModal}
        loadingDelete={loading}
        submitHandler={handleInspectionWaiver}
      />
      <ResendModal
        show={showResendModal}
        onHide={toggleResendModal}
        loadingDelete={loading}
        submitHandler={updateStatus}
        id={props.auctionId}
        data={Params}
      />
      <ReactivateModal
        show={showReactivateModal}
        onHide={toggelReactivateModal}
        loadingDelete={loading}
        submitHandler={updateStatus}
        id={props.auctionId}
        data={Params}
      />
      <DeactivateModal
        show={showDeactivateModal}
        onHide={toggelDeactivatedModal}
        loadingDelete={loading}
        submitHandler={updateStatus}
        id={props.auctionId}
        data={Params}
      />

      <MarkPaidModal
        show={showMarkPaidModal}
        onHide={toggleMarkPaidModal}
        statusData={statusData}
        setStatusData={setStatusData}
        data={statusStaticData}
        handleSubmit={invoiceStatusUpdate}
        loading={loading}
      />

      <DeActivateRateModal
        show={showDeactivateRateModal}
        onHide={toggleDeactivateModal}
        statusData={statusData}
        setStatusData={setStatusData}
        data={statusStaticData}
        handleSubmit={invoiceStatusUpdate}
        loading={loading}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getInvoiceList: (params, callback) => dispatch(getInvoiceList(params, callback)),
  updateInvoiceStatus: (params, data, callback) => dispatch(updateInvoiceStatus(params, data, callback)),
  getBids: (params, callback) => dispatch(getBids(params, callback)),
  updateDepositPaid: (params, data, callback) => dispatch(updateDepositPaid(params, data, callback)),
  updateCollect: (params, data, callback) => dispatch(updateCollect(params, data, callback)),
  getFeedback: (params, invoiceId, action, callback) => dispatch(getFeedback(params, invoiceId, action, callback)),
  inspectionWaiver: (params, data, callback) => dispatch(inspectionWaiver(params, data, callback)),
  invoiceAddNote: (params, data, callback) => dispatch(invoiceAddNote(params, data, callback)),
});

const mapStateToProps = (state) => ({
  invoices: getInvoice(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Invoices));
