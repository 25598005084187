import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import bellSound from "../include/sound/bell.mp3";

//Images
import logo from "../include/images/logo.svg";
import searchIcon from "../include/images/search.svg";
import notificationIcon from "../include/images/message-circle.svg";
import {
  checkToken,
  clearAllToken,
  getProfile,
  setLogOutRedirect,
  setRedirectUrl,
} from "../utils.js/localStorageServices";
import { getChats, loadChatList, updateUnreadCount } from "../store/chats";
import { connect } from "react-redux";
import ScrollButton from "../common/scrollTop/scrollTop";
import { questionDetails } from "../store/questions";
import SidebarOffcanvas from "./sidebarOffcanvas";

const Header = (props) => {
  const [search, setSearch] = useState("");
  const [showSidebarOffcanvas, setShowSidebarOffcanvas] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");

  const history = useHistory();
  const [unreadCount, setUnreadCount] = React.useState(0);

  useEffect(() => {
    if (!checkToken()) {
      const a = window.location.href;
      setRedirectUrl(a);
      // props.history.push("/signin");
    }
  }, []);

  useEffect(() => {
    if (checkToken()) {
      let adminUnreadCount = 0;

      if (props.getChats.chats) {
        props.getChats.chats.forEach((chat) => {
          if (chat.adminUnreadCount) adminUnreadCount = adminUnreadCount + chat.adminUnreadCount;
        });
        setUnreadCount(adminUnreadCount);
      }
    }
  }, [props.getChats.chats]);

  const member = getProfile();

  // useEffect(() => {
  //   if (lastMessage !== null && checkToken()) {
  //     const data = JSON.parse(lastMessage.data);
  //     if (data?.type === "message") {
  //       const audio = new Audio(bellSound);
  //       audio.play();
  //       const mess = data.data;
  //       props.updateUnreadCount(data?.data);
  //       //push to list
  //     } else {
  //       props.loadChat(data?.user_id, (res) => {});
  //     }
  //   }
  // }, [lastMessage]);

  useEffect(() => {
    if (checkToken()) {
      props.loadChat(member?.user_id);
    }
  }, []);
  const unreadQuestionsCount = props?.questionDetails?.allUnreadCount?.data;

  return (
    <>
      <header id="header">
        <nav class="navbar navbar-expand-xl vw-100">
          <div class="container-fluid">
            <div class="nav-inside d-flex align-items-center justify-content-between">
              <a class="navbar-brand" href="javascript:void(0)">
                <img src={logo} alt="" className="cursor-pointer" onClick={() => history.push("/")} />
              </a>
              <div class="header-right d-flex flex-wrap align-items-center gap-3 ms-xl-3 mobile-view-nav">
                <div class="header-name-box fw-medium dropdown mobile-view-nav ms-auto">
                  <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="d-none d-xl-block">Welcome, Chance Schleifer!</span>
                    <div class="d-xl-none header-profile-box">
                      {/* <img src={member?.profile_avatar} style={{ height: "35px", width: "35px" }} alt="" /> */}
                      <div class="custom-profile-mobile">
                        <p className="chat-dp">
                          {member?.name01.split(" ")[0] && member?.name01.split(" ")[0][0]}
                          {member?.name01.split(" ")[1] && member?.name01.split(" ")[1][0]}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a class="dropdown-item" href="#!">
                      Option 1
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#!">
                      Option 2
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#!">
                      Option 3
                    </a>
                  </li>
                </ul> */}
                </div>
                <div class="collapse navbar-collapse" style={{ marginRight: "15px" }}>
                  <div class="navbar-inside w-100 d-flex align-items-center header-top-bar">
                    <div class="header-search-box position-relative" style={{ marginLeft: "auto" }}>
                      <input
                        class="w-100"
                        type="search"
                        placeholder="Enter Auction ID or Keywords"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        onKeyDown={(k) => {
                          if (k.key === "Enter") {
                            history.push("/", { search });
                          }
                        }}
                      />
                      <img class="search__icon" src={searchIcon} alt="" onClick={() => history.push("/", { search })} />
                    </div>
                  </div>
                </div>
                <button
                  class="navbar-toggler collapsed ms-auto"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#mainNav"
                  aria-controls="mainNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => setShowSidebarOffcanvas(!showSidebarOffcanvas)}
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
              </div>
              <div class="collapse navbar-collapse desktop-nav" style={{ marginRight: "15px" }}>
                <div class="navbar-inside w-100 d-flex align-items-center header-top-bar">
                  <div class="header-search-box position-relative" style={{ marginLeft: "auto" }}>
                    <input
                      class="w-100"
                      type="search"
                      placeholder="Enter Auction ID or Keywords"
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      onKeyDown={(k) => {
                        if (k.key === "Enter") {
                          history.push("/", { search });
                        }
                      }}
                    />
                    <img class="search__icon" src={searchIcon} alt="" onClick={() => history.push("/", { search })} />
                  </div>
                </div>
              </div>
              <button
                class="navbar-toggler collapsed ms-auto desktop-nav"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mainNav"
                aria-controls="mainNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setShowSidebarOffcanvas(!showSidebarOffcanvas)}
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="header-right d-flex flex-wrap desktop-nav">
                <div class="header-icon-item" onClick={() => history.push("/chats")}>
                  {/* Notification to be included in future */}
                  <div class="header-notify-box w-100 h-100 position-relative">
                    {unreadQuestionsCount ? <span class="notify-count">{unreadQuestionsCount}</span> : ""}
                    <img src={notificationIcon} alt="" />
                  </div>
                </div>
                <Dropdown bsPrefix="header-name-box fw-medium dropdown cursor-pointer">
                  <Dropdown.Toggle as="span" id="dropdown-basic" className="dropdown-toggle">
                    {" "}
                    Welcome, {member && member.name01}!
                  </Dropdown.Toggle>

                  <Dropdown.Menu bsPrefix="dropdown-menu dropdown-menu-end">
                    <Dropdown.Item
                      href="/signin"
                      onClick={() => {
                        const a = window.location.href;
                        setLogOutRedirect(a);
                        clearAllToken();
                      }}
                    >
                      Sign Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <ScrollButton />
      <SidebarOffcanvas
        show={showSidebarOffcanvas}
        onHide={() => setShowSidebarOffcanvas(!showSidebarOffcanvas)}
        showMenu={true}
        setShowMenu={setShowMenu}
        menuList={menuList}
        setMenuList={setMenuList}
        page="listing"
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadChat: (userId, callback) => dispatch(loadChatList(userId, callback)),
  updateUnreadCount: (data) => dispatch(updateUnreadCount(data)),
});

const mapStateToProps = (state) => ({
  getChats: getChats(state),
  questionDetails: questionDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Header));
