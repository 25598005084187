import React, { useEffect, useState } from "react";
import extLink from "../../include/images/external-link.svg";
import DateFormat from "../DateFormat/dateFormat";
import icon1 from "../../include/images/icon___1.svg";
import icon2 from "../../include/images/icon__2.svg";
import icon3 from "../../include/images/red-hand.png";
import greenEmail from "../../include/images/green-mail.svg";
import notVerified from "../../include/images/mail-not-verified.svg";
import invalid from "../../include/images/mail-invalid.svg";
import spamEmail from "../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../include/images/mail-purple.svg";
import More from "../../include/images/more-icon.svg";
import msgSqr from "../../include/images/message-square.svg";
import Select from "react-select";
import { flatten } from "lodash";
import { toast } from "react-toastify";
import AlertError from "../alerts/alertError";
import {
  availableToView,
  dashboardData,
  deleteMoreButtonChat,
  getAuctionDetails,
  getCategoryData,
  getMotivation,
  getPricing,
  getTasks,
  otherAuctions,
  postMessage,
  updateAuctionField,
  updateMotivation,
  updatePricing,
} from "../../store/dashBoard";
import { getRequestor, payableDetails } from "../../store/payables";
import { commentsAndNotesDetails, getTaskType } from "../../store/commentsAndNotes";
import { connect } from "react-redux";
import NextButton from "../form/nextButton";
import ActivationModal from "../../layouts/modals/bids/activationModal";
import AlertSuccess from "../alerts/alertSuccess";
import CategoryPreferenacesModal from "../../layouts/modals/categoryPreferencesModal/categoryPreferencesModal";
import { updateCategoryPreference } from "../../store/listings";
import { updateLocationSecurity } from "../../store/productLocation";
import { deactivateBid, getRevisitSellerBid, updatePriceFeature } from "../../store/latestActivity";
import EditProductDetails from "../../layouts/modals/editProductDetails";
import {
  getListingProductDetail,
  listingProductDetailReceived,
  moreDetailsFailed,
  productDetails,
  updateProductDetails,
} from "../../store/listingProduct";
import EditInternalPeaople from "../../layouts/modals/editInternalPeople/editInternalPeaople";
import MotivationModal from "../../layouts/modals/dashboard/motivation/motivationModal";
import { updateTaskStatus } from "../../store/tasks";
import DeleteModal from "../../layouts/modals/deleteModal";
import { getProfile } from "../../utils.js/localStorageServices";
import InProgressModal from "../../layouts/modals/taskModal/inProgressModal";
import moment from "moment";
import CompleteTaskModal from "../../layouts/modals/taskModal/completeTaskModal";
import { TextField } from "@mui/material";
import closeButton from "../../include/images/close.svg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Editpricing from "../../layouts/modals/editpricing";
import DeclineModal from "../../layouts/modals/bids/declineModal";
import { declineBid } from "../../store/bids";
import PushCategories from "../../layouts/modals/categoryPreferencesModal/pushCategories";
import ApplyTeam from "../../layouts/modals/moreButton/applyTeam";
const customStyles1 = {
  indicatorSeparator: (styles) => ({ display: "none" }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#47ad1d",
      color: "#fff",
    },
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: state.isSelected ? "#fff" : "black",
    backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
    // maxHeight: "84px",
  }),
  control: (base, state) => ({
    ...base,
    // height: 60,
    minHeight: 42,
    borderColor: state.isFocused ? "#47AD1D" : "#e4e4e4",
    boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    border: state.isHovered ? "0" : "1px solid #e4e4e4",

    "&:hover": {
      borderColor: state.isFocused ? "#47AD1D" : "0",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    },
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    "&:hover": {
      backgroundColor: "rgb(95, 50, 187, 10%)",
      color: "#6119c0",
    },
  }),
};
const MoreButton = (props) => {
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const more = props?.dashboardData?.auctionDetail?.data;
  const requestors = props.payableDetails?.requestor?.data;
  const taskTypes = props.commentsAndNotesDetails?.tasks?.data;
  const tasks = props.dashboardData?.tasks?.data;
  const [loadingAucDetails, setLoadingAucDetails] = useState(false);
  const [categoryToggle, setCategoryToggle] = useState(false);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [locSec, setLocSec] = useState("");
  const [bidId, setBidId] = useState("");
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showtasks, setShowTasks] = useState(false);
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [taskOption, setTaskOption] = useState(null);
  const [adminOption, setAdminOption] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [email, setEmail] = useState(null);
  const [loadingActivation, setLoadingActivation] = useState(false);
  const [loadingchatTask, setLoadingchatTask] = useState(false);
  const [showMotivationModal, setShowMotivationModal] = useState(false);
  const [motivationData, setMotivationData] = useState({
    motivation: "",
  });
  const [loadingMotivation, setLoadingMotivation] = useState(false);
  const [statusData, setStatusData] = useState({});
  const [messageData, setMessageData] = useState({
    universal: true,
    task_type: 0,
    to_id: [],
    message: "",
    send_email: true,
    all_seller_listings: false,
    cc_emails_list: [],
  });
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [categoryDropdown, setCategoryDropdown] = useState(null);
  const [checkList, setCheckList] = useState([]);
  const [select, setSelect] = useState();
  const [subCat, setSubCat] = useState();
  const categoryData = props?.dashboardData?.auctionDetail?.data || {};
  const [availableToView, setAvailableToView] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [priceFeatureData, setPriceFeatureData] = useState({
    f_1001: more?.asking_price_feature,
    f_1002: more?.retail_value_feature,
    f_1003: more?.wholesale_value_feature,
    f_1004: more?.claim_value_feature,
    p_1003: more?.orig_wholesale_value.toString(),
    p_1004: more?.claim_value.toString(),
    p_1002: more?.retail_value.toString(),
    p_1001: more?.public_reserve.toString(),
    scrap_value: more?.recycle_value,
    seller_reserve: more?.seller_reserve,
  });
  const [taskId, setTaskId] = useState("");
  const [revisitId, setRevisitId] = useState(null);
  const [catId, setCatId] = useState(0);
  const [revisit, setRevisit] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const session = getProfile();
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [revisitSellerBid, setRevisitSellerBid] = useState(false);
  const [loadingDecline, setLoadingDecline] = useState(false);
  const [showPush, setShowPush] = useState(false);
  const [showApplyTeam, setShowApplyTeam] = useState(false);
  const [applyTeamListing, setApplyTeamListing] = useState([]);
  const [aucIds, setAucIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [declineData, setDeclineData] = useState({
    decline: 0,
    new_message: "",
  });

  //Toggle Apply Team
  const toggleApplyTeam = () => {
    setAucIds([]);
    setShowApplyTeam(!showApplyTeam);
  };

  //Apply Team Get Listing
  const getListingApplyTeam = (id) => {
    const payload = {
      action: "apply_to_other_listings",
      type: "getListing",
      auction_id: id,
    };
    props?.refStart();
    props?.otherAuctions(payload, (res) => {
      if (res.status === 200) {
        setApplyTeamListing(res?.data?.data);
        props?.refStop();
        toggleApplyTeam();
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  //Save Apply Team
  const handleSaveApplyTeam = () => {
    setLoading(true);
    props?.refStart();
    const payload = {
      action: "apply_to_other_listings",
      type: "applyToListing",
      auction_id: more.auction_id,
      auction_ids: aucIds,
    };
    props?.otherAuctions(payload, (res) => {
      if (res.status === 200) {
        props?.refStop();
        setLoading(false);
        toggleApplyTeam();
      } else {
        props?.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  // whole category data htmlFor edit c
  const categoryDropDownData =
    (props.dashboardData &&
      props.dashboardData.categoryData &&
      props.dashboardData.categoryData.data &&
      props.dashboardData.categoryData.data.categories) ||
    {};

  const data = (props.dashboardData && props.dashboardData.pricing && props.dashboardData.pricing.data) || {};

  useEffect(() => {
    setCatId(more?.category_id);
  }, [more]);

  const [editInternalPeople, setEditInternalPeople] = useState(false);
  const handleSubmit = () => {
    setLoadingCategory(true);
    props.parentprops.refStart();
    const id = props.auctionId;
    const data = {
      category_id: catId,
      category_id_push: checkList.toString(),
    };
    props.updateCategoryPreference(id, data, (res) => {
      if (res.status === 200) {
        props.getAuctionDetails(props.auctionId, (res) => {
          if (res.status === 200) {
            setLoadingCategory(false);
            toggleCategoryPreferenaces();
            props.parentprops.refStop();
            toast(<AlertSuccess message="Information Saved" />);
          } else {
            setLoadingCategory(false);
            props.parentprops.refStop();
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      } else {
        setLoadingCategory(false);
        props.parentprops.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  //Togle Delete Modal
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const toggleProgressModal = () => {
    setShowProgressModal(!showProgressModal);
  };
  const toggleCompleteModal = () => {
    setShowCompleteModal(!showCompleteModal);
  };
  const toggleDeclineModal = () => {
    setShowDeclineModal(!showDeclineModal);
  };
  useEffect(() => {
    const fetchData = () => {
      props.parentprops.refStart();
      props.getAuctionDetails(props.auctionId, (res) => {
        if (res.status === 200) {
          props.parentprops.refStop();
          setLoadingAucDetails(true);
          setLocSec(res.data.data.loc_sec);
          setAvailableToView(res?.data?.data?.available_view);
          props.getRequestor((res) => {});
          props.getTaskType((res) => {});
          props.getCategoryData(props.auctionId, (res) => {});
        } else {
          setLoadingAucDetails(true);
          props.parentprops.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    props.showMore && fetchData();
    if (props.auctionId) {
      setLoadingDetails(true);
      props.getPricing(props.auctionId, (res) => {
        if (res.status === 200) {
          setLoadingDetails(false);
        }
      });
    }
  }, []);
  const handleAvailabletoV = (e) => {
    props.parentprops.refStart();
    let payload;
    if (e.target.value === "yes") {
      payload = {
        availableView: 1,
      };
      setAvailableToView(true);
    } else {
      payload = {
        availableView: 0,
      };
      setAvailableToView(false);
    }
    props.availableToView(props.auctionId, payload, (res) => {
      if (res.status === 200) {
        props.parentprops.refStop();
      } else {
        props.parentprops.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleRefresh = () => {
    props?.parentprops?.refStart();
    props.getAuctionDetails(props.auctionId, (res) => {
      if (res.status === 200) {
        props?.parentprops?.refStop();
      } else {
        props?.parentprops?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  useEffect(() => {
    const fetchData = () => {
      props.parentprops.refStart();
      props.getAuctionDetails(props.auctionId, (res) => {
        if (res.status === 200) {
          setPriceFeatureData({
            f_1001: res.data.data.asking_price_feature,
            f_1002: res.data.data.retail_value_feature,
            f_1003: res.data.data.wholesale_value_feature,
            f_1004: res.data.data.claim_value_feature,
            p_1003: res.data.data.orig_wholesale_value.toString(),
            p_1004: res.data.data.claim_value.toString(),
            p_1002: res.data.data.retail_value.toString(),
            p_1001: res.data.data.public_reserve.toString(),
            scrap_value: res.data.data.recycle_value,
            seller_reserve: res.data.data.seller_reserve,
          });
          props.parentprops.refStop();
        } else {
          props.parentprops.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    if (!editModal || !editInternalPeople) {
      fetchData();
    }
  }, [editInternalPeople]);
  useEffect(() => {
    if (!loadingMessage) {
      props.getAuctionDetails(props.auctionId, (res) => {
        if (res.status !== 200) {
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [loadingMessage]);
  useEffect(() => {
    const data =
      categoryDropDownData &&
      categoryDropDownData.length > 0 &&
      categoryDropDownData.map((item) => ({
        name: item.description,
        value: item.category_id,
      }));
    setCategoryDropdown(data);

    const sub =
      categoryDropDownData &&
      categoryDropDownData.length > 0 &&
      categoryDropDownData.map((item) => ({
        subCategories: item.subCategories,
        value: item.category_id,
      }));
    setSubCat(sub);
  }, [categoryToggle]);
  useEffect(() => {
    if (categoryDropDownData) {
      const a = {};
      const b =
        categoryDropDownData &&
        categoryDropDownData.length > 0 &&
        categoryDropDownData?.find(
          (item) => item.category_id === props?.dashboardData?.categoryData?.data?.category_id,
        );
      a.name = b?.description ? b?.description : "";
      a.value = b?.category_id ? b?.category_id : "";
      setSelect({ ...a });
    }

    {
      const a =
        categoryData && categoryData.push_categories && categoryData.push_categories.length > 0
          ? categoryData.push_categories.map((item) => item.category_id)
          : [];
      setCheckList(a);
    }
  }, [categoryToggle]);
  const handleUpdatePriceFeature = (e) => {
    if (props.auctionId) {
      const data = { ...priceFeatureData };
      const { name, value } = e.target;
      data[name] = JSON.parse(value);
      setPriceFeatureData(data);
      props.parentprops.refStart();
      setLoadingDetails(true);
      props.updatePricing(props.auctionId, data, (res) => {
        if (res.status === 200) {
          props.getPricing(props.auctionId, (res) => {
            if (res.status === 200) {
              setLoadingDetails(false);
              props.parentprops.refStop();
            }
          });
        } else {
          props.parentprops.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  };
  const formatPercent = (a, b) => {
    if (b > 0) return (a / b) * 100;
    else return 0;
  };
  const toggleCategoryPreferenaces = () => {
    setCategoryToggle(!categoryToggle);
  };
  const toggleActivationModal = () => {
    setShowActivationModal(!showActivationModal);
  };

  //View Completed Tasks
  const viewCompletedTasks = () => {
    setShowTasks(false);
    setLoadingTasks(true);
    props.parentprops.refStart();
    props.getTasks(props.auctionId, (res) => {
      if (res.status === 200) {
        setLoadingTasks(false);
        setShowTasks(true);
        props.parentprops.refStop();
      } else {
        props.parentprops.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  //Location Security Handle Change
  const handleChange = (e) => {
    setLocSec(e.target.value);
    setLoadingLocation(true);
    const data = { loc_sec: e.target.value };
    props.updateLocSec(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props.getAuctionDetails(props.auctionId, (res) => {
          if (res.status === 200) {
            setLocSec(res.data.data.loc_sec);
          }
        });
        setLoadingLocation(false);
      } else {
        setLoadingLocation(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const handleActivation = () => {
    setLoadingActivation(true);
    props.parentprops.refStart();
    const data = {};
    data.b_id = bidId;
    props.deactivateBid(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props.getAuctionDetails(props.auctionId, (res) => {});
        setLoadingActivation(false);
        props.parentprops.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
        toggleActivationModal();
      } else {
        setLoadingActivation(false);
        props.parentprops.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  //Post Message
  const postMessage = () => {
    if (messageData.message) {
      setLoadingMessage(true);
      props.parentprops.refStart();
      props.postMessage(props.auctionId, messageData, (res) => {
        if (res.status === 200) {
          setLoadingMessage(false);
          setTaskOption(null);
          setAdminOption(null);
          setEmail("");
          props.parentprops.refStop();
          setMessageData({
            universal: true,
            task_type: 0,
            to_id: [],
            message: "",
            send_email: true,
            all_seller_listings: false,
            cc_emails_list: [],
          });
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          setLoadingMessage(false);
          props.parentprops.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    } else {
      toast(<AlertError message={"Please enter the Message"} />);
    }
  };
  const toggleEditModal = () => {
    setEditModal(!editModal);
  };
  const deleteChat = () => {
    setLoadingMessage(true);
    props.parentprops.refStart();
    props.deleteMoreButtonChat(props.auctionId, { chat_id: taskId }, (res) => {
      if (res.status === 200) {
        setLoadingMessage(false);
        props.parentprops.refStop();
        toggleDeleteModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingMessage(false);
        props.parentprops.refStop();
        toggleDeleteModal();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  //Handle Processes
  const handleTaskProcess = () => {
    props.parentprops.refStart();
    setLoadingMessage(true);
    props.updateTaskStatus(props.auctionId, statusData, (res) => {
      if (res.status === 200) {
        setLoadingMessage(false);
        props.parentprops.refStop();
        if (statusData.status_id === 3) {
          toast(<AlertSuccess message="Information Saved" />);
          toggleCompleteModal();
        }
        if (statusData.status_id === 4) {
          toast(<AlertSuccess message="Information Saved" />);
          toggleProgressModal();
        }
        if (statusData.status_id === 5) {
          setLoadingMessage(false);
          toast(<AlertSuccess message="Information Saved" />);
          setRevisit(false);
        }
      } else {
        toast(<AlertError message={res?.data?.message ? res.data.message : "Something Went Wrong"} />);
        setLoadingMessage(false);
        props.parentprops.refStop();
      }
    });
  };
  const tasksOptions = flatten(taskTypes)?.map((task, index) => ({
    key: `task-${index}`,
    value: task?.task_type,
    label: task?.description,
    further: task?.further,
    options: task?.options,
  }));
  const openEditInternal = () => {
    setEditInternalPeople(!editInternalPeople);
  };

  const toggleMotivationModal = () => {
    setShowMotivationModal(!showMotivationModal);
  };
  const togglePush = () => {
    setShowPush(!showPush);
  };

  const fetchMotivation = () => {
    props.refStart();
    props.getMotivaiton(more.s_user_id, (res) => {
      if (res.status === 200) {
        const data = { ...motivationData };
        data.motivation = res?.data?.data?.motivation;
        setMotivationData(data);
        props.refStop();
        toggleMotivationModal();
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const handleUpdateMotivation = () => {
    setLoadingMotivation(true);
    props.refStart();
    props.updateMotivation(more.s_user_id, motivationData, (res) => {
      if (res.status === 200) {
        props.getAuctionDetails(props.auctionId, (res) => {
          if (res.status === 200) {
            setLoadingMotivation(false);
            props.refStop();
            toggleMotivationModal();
          } else {
            props.refStop();
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
          }
        });
      } else {
        setLoadingMotivation(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleRevisitSellerBid = () => {
    props.refStart();
    props.getRevisitSellerBid(props.auctionId, (res) => {
      if (res.status === 200) {
        props.getAuctionDetails(props.auctionId, (res) => {});
        props.refStop();
        setRevisitSellerBid(res.data.revisit_seller_bid_date);
      } else {
        props.refStop();
        toast(<AlertError messge={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Handle Decline
  const handleDecline = () => {
    setLoadingDecline(true);
    props.refStart();
    props.declineBid(props.auctionId, declineData, (res) => {
      if (res.status === 200) {
        setLoadingDecline(false);
        props.getAuctionDetails(props.auctionId, (res) => {});
        props.refStop();
        toggleDeclineModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingDecline(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Handle Update Auction Fields
  const handleUpdateAuctionField = (data) => {
    props?.refStart();
    const payload = {
      field: data,
    };
    props.updateAuctionField(props.auctionId, payload, (res) => {
      if (res.status === 200) {
        props.getAuctionDetails(props?.auctionId, (res) => {});
        props?.refStop();
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  return (
    more &&
    loadingAucDetails && (
      <tr>
        <td colSpan={15}>
          <div class="table-responsive position-relative">
            <table class="table table-bordered custom-table mb-0">
              <tbody>
                <tr style={{ border: " 8px solid #C4E26A" }}>
                  <td style={{ maxWidth: "100px" }}>
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: more?.DPHistory?.replace(/null/g, "0"),
                        }}
                      />
                    </div>
                  </td>
                  <td style={{ minWidth: "65px", maxWidth: "75px" }}>
                    <div>
                      <div>
                        <img src={more.primary_picture} alt="Primary Picture" />
                      </div>
                    </div>
                  </td>
                  <td style={{ maxWidth: "230px" }}>
                    <div>
                      <div className="d-flex">
                        <a href={`listing-detail?id=${more.auction_id}`} target="_blank" className="green-btn-text">
                          {more.auction_id} - {more.title}
                        </a>
                      </div>
                      <div className="mt-3">
                        <label htmlFor="" class="form-label fw-medium">
                          Main Category :
                        </label>
                        <input type="text" value={more.category_description} disabled class="form-control" />
                        <label htmlFor="" class="form-label fw-medium mt-2">
                          Push Categories :
                        </label>
                        <div class="table-responsive">
                          <table class="table align-middle product-table">
                            <colgroup>
                              <col style={{ width: "36%" }} />
                            </colgroup>
                            <tbody>
                              {more?.push_categories?.map((item) => {
                                if (item.subCategories.length > 0) {
                                  return item.subCategories.map((subCategory, index) => (
                                    <tr key={index}>
                                      <td>
                                        {subCategory} &gt; {item.description}
                                      </td>
                                    </tr>
                                  ));
                                } else {
                                  return (
                                    <tr key={item.category_id}>
                                      <td>{item.description}</td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <button
                        className="btn btn-primary btn-xs-size btn-auto mt-3"
                        onClick={toggleCategoryPreferenaces}
                      >
                        EDIT CATEGORIES
                      </button>
                      <div className="mt-3 mb-1">
                        <div className="mb-2">
                          <span>Status : </span>
                          <span className="fw-medium">{more.status_description}</span>
                        </div>
                        <div className="mb-1">
                          <span>Location : </span>
                          <span className="fw-medium">
                            {more.auction_city} ,{" "}
                            <a href="javascript:void(0)" className="green-btn-text">
                              {more.auction_country}
                            </a>
                          </span>
                        </div>
                        <div className="mb-1">
                          <span>End Date : </span>
                          <span className="fw-medium">
                            <DateFormat date={more.end_date} />
                          </span>
                        </div>
                        <label htmlFor="" class="form-label mt-2">
                          Description :
                        </label>
                        <div
                          className="form-control more-description-box"
                          dangerouslySetInnerHTML={{
                            __html: more.auction_description,
                          }}
                        />

                        <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                          <div className="mt-2" style={{ width: "100%" }}>
                            <button
                              className={`btn ${more?.mega ? "more-selected-btn" : "more-default-btn"}`}
                              style={{ width: "100%" }}
                              onClick={() => handleUpdateAuctionField("mega")}
                            >
                              Exclusive Contract
                            </button>
                          </div>
                          <div className="mt-2" style={{ width: "100%" }}>
                            <button
                              className={`btn ${more?.bigdeal ? "more-selected-btn" : "more-default-btn"}`}
                              style={{ width: "100%" }}
                              onClick={() => handleUpdateAuctionField("bigdeal")}
                            >
                              Big Deal
                            </button>
                          </div>
                          <div className="mt-2" style={{ width: "100%" }}>
                            <button
                              className={`btn ${more?.hotlist ? "more-selected-btn" : "more-default-btn"}`}
                              style={{ width: "100%" }}
                              onClick={() => handleUpdateAuctionField("hotlist")}
                            >
                              Project Listing
                            </button>
                          </div>
                          <div className="mt-2" style={{ width: "100%" }}>
                            <button
                              className={`btn ${more?.scrap ? "more-selected-btn" : "more-default-btn"}`}
                              style={{ width: "100%" }}
                              onClick={() => handleUpdateAuctionField("scrap")}
                            >
                              Scrap
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="page-widget-body mt-3">
                        <div class="pl-flex-row">
                          <div class="pl-flex-row">
                            {loadingLocation ? (
                              <div class="pl-flex-head text-center yellow-bg">Loading...</div>
                            ) : (
                              <div class="pl-flex-head text-center yellow-bg">
                                {locSec === 0 ? (
                                  more?.location1
                                ) : locSec === 1 ? (
                                  more?.location2
                                ) : locSec === 2 ? (
                                  more?.location3
                                ) : locSec === 3 ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: more?.location4,
                                    }}
                                  />
                                ) : (
                                  "Loading..."
                                )}
                              </div>
                            )}
                            <div class="table-responsive">
                              <table class="table align-middle product-table">
                                <colgroup>
                                  <col style={{ width: "36%" }} />
                                  <col />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td>
                                      <span>Location Security </span>
                                    </td>
                                    <td>
                                      <div class="td-radio-group">
                                        <div class="form-check radio">
                                          <input
                                            class="form-check-input cursor-pointer"
                                            type="radio"
                                            name={`${props.auctionId}-location`}
                                            id="continent"
                                            value={0}
                                            checked={locSec === 0}
                                            onChange={handleChange}
                                          />
                                          <label class="form-check-label" htmlFor="continent">
                                            Continent
                                          </label>
                                        </div>
                                        <div class="form-check radio">
                                          <input
                                            class="form-check-input cursor-pointer"
                                            type="radio"
                                            name={`${props.auctionId}-location`}
                                            id="region"
                                            value={1}
                                            checked={locSec === 1}
                                            onChange={handleChange}
                                          />
                                          <label class="form-check-label" htmlFor="region">
                                            Region
                                          </label>
                                        </div>
                                        <div class="form-check radio">
                                          <input
                                            class="form-check-input cursor-pointer"
                                            type="radio"
                                            name={`${props.auctionId}-location`}
                                            id="cityStateCountry"
                                            value={2}
                                            checked={locSec === 2}
                                            onChange={handleChange}
                                          />
                                          <label class="form-check-label" htmlFor="cityStateCountry">
                                            City, State/Country
                                          </label>
                                        </div>
                                        <div class="form-check radio">
                                          <input
                                            class="form-check-input cursor-pointer"
                                            type="radio"
                                            name={`${props.auctionId}-location`}
                                            id="exact"
                                            value={3}
                                            checked={locSec === 3}
                                            onChange={handleChange}
                                          />
                                          <label class="form-check-label" htmlFor="exact">
                                            Exact
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a
                        className="btn btn-primary btn-xs-size btn-auto mt-3"
                        href={
                          process.env.REACT_APP_MODE == "development"
                            ? `https://admin-dev.salvex.com/admin/_listings/views/all_participants_email.cfm?aucID=${props?.auctionId}`
                            : `https://admin.salvex.com/admin/_listings/views/all_participants_email.cfm?aucID=${props?.auctionId}`
                        }
                        target="_blank"
                      >
                        SEND APEP
                      </a>
                    </div>
                  </td>
                  <td style={{ minWidth: "190px", maxWidth: "200px" }}>
                    <div>
                      <div
                        onClick={() => {
                          if (more?.seller_name) {
                            if (process.env.REACT_APP_MODE == "development") {
                              window.open(
                                `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${more?.s_user_id}`,
                              );
                            } else {
                              window.open(`https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${more?.s_user_id}`);
                            }
                          }
                        }}
                      >
                        <img src={`${mediaUrl}/co/logos/${more.proc_logo}`} alt="" />
                      </div>
                      <div className="fw-medium green-text mt-1">{more.seller_project_name}</div>
                      <div className="mt-3">
                        <a
                          href="javascript:void(0)"
                          className="green-btn-text me-2"
                          onClick={() => {
                            if (more?.seller_name) {
                              if (process.env.REACT_APP_MODE == "development") {
                                window.open(
                                  `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${more?.s_user_id}`,
                                );
                              } else {
                                window.open(
                                  `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${more?.s_user_id}`,
                                );
                              }
                            }
                          }}
                        >
                          {more.seller_name}
                        </a>

                        <div class="td-icon-list d-flex flex-wrap align-items-center">
                          {more.seller_dhs_approved ? (
                            <div class="td-icon-item">
                              <div class="td-icon-box">
                                <img src={icon1} alt="" />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {more.seller_okay ? (
                            <div class="td-icon-item">
                              <div class="td-icon-box">
                                <img src={icon2} alt="" />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {more.seller_stop_working ? (
                            <div class="td-icon-item">
                              <div class="td-icon-box">
                                <img src={icon3} alt="" width="30px" height="30px" />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {more.seller_designation ? (
                          <div className="fw-medium green-text">{more.seller_designation}</div>
                        ) : (
                          <div className="green-text">No designation</div>
                        )}
                      </div>

                      <div className="mt-3">
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            if (more?.seller_name) {
                              if (process.env.REACT_APP_MODE == "development") {
                                window.open(
                                  `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${more?.s_user_id}`,
                                );
                              } else {
                                window.open(
                                  `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${more?.s_user_id}`,
                                );
                              }
                            }
                          }}
                        >
                          {more.seller_company}
                        </div>
                        <div>{more.seller_location}</div>
                        <div>
                          <a href="javascript:void(0)" className="green-btn-text">
                            {more.seller_email}
                          </a>
                          <div class="d-text-item">
                            {more.emailStatusCode === 0 ? (
                              <img src={greenEmail} alt="" title="Email OK" />
                            ) : more.emailStatusCode === 1 ? (
                              <img
                                src={purpleEmail}
                                alt=""
                                title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                              />
                            ) : more.emailStatusCode === 2 ? (
                              <img src={invalid} alt="" title="Email Invalid" />
                            ) : more.emailStatusCode === 3 ? (
                              <img src={spamEmail} alt="" title="Email Blocked by Spam or Firewall" />
                            ) : (
                              <img src={notVerified} alt="" title="Email Not Verified" />
                            )}
                          </div>
                        </div>
                        <div class="td-button-group">
                          <img
                            src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${more.seller_email}`}
                            alt=""
                          />
                        </div>
                        <span>{more.seller_phone}</span>
                      </div>
                      <div className="mt-2">
                        <span className="me-2">Success Rate:</span>
                        <span className="fw-medium me-2">
                          {more.success_rate
                            ? new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(more.success_rate) + "%"
                            : "0.00%"}
                        </span>
                        <span className="fw-medium">
                          {more.rate1}/{more.rate2}
                        </span>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary btn-xs-size btn-auto mt-3"
                      onClick={() => {
                        if (process.env.REACT_APP_MODE == "development") {
                          window.open(
                            `https://admin-dev.salvex.com/admin/_sellers/_mrk_doc_auc/_create.cfm?s_user_id=${more.s_user_id}&aucID=${more.auction_id}`,
                          );
                        } else {
                          window.open(
                            `https://admin.salvex.com/admin/_sellers/_mrk_doc_auc/_create.cfm?s_user_id=${more.s_user_id}&aucID=${more.auction_id}`,
                          );
                        }
                      }}
                    >
                      Send Marketing Email
                    </button>

                    <div
                      className="full-width-row mb-3 yellow-bg d-flex align-items-center justify-content-between mt-3 mx-1"
                      style={{ width: "100%" }}
                    >
                      <h4 class="mb-0">Motivation</h4>
                      <a
                        href="javascript:void(0)"
                        class="edit-btn-button seller-motivation-edit"
                        onClick={fetchMotivation}
                      >
                        Edit
                      </a>
                    </div>
                    {more?.motivation ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: more.motivation,
                        }}
                      />
                    ) : (
                      <>
                        <p>
                          <em>Motivation not entered.</em>
                        </p>
                        <ol>
                          <li>Price vs. Wholesale Value?</li>
                          <li>Time frame to get the asset removed?</li>
                          <li>Level of competition or seller retention?</li>
                        </ol>
                      </>
                    )}
                    {more.otherDeals && more.otherDeals.length > 1 && (
                      <div
                        className="full-width-row mb-3 yellow-bg d-flex align-items-center justify-content-between mt-3 mx-1"
                        style={{ width: "100%" }}
                      >
                        <h4 class="mb-0">Other Deals</h4>
                      </div>
                    )}
                    <div className="mt-2 mx-1" style={{ minHeight: "0px", maxHeight: "auto" }}>
                      <ul>
                        {more.otherDeals &&
                          more.otherDeals.length > 1 &&
                          more.otherDeals?.map((val, i) => (
                            <li key={i} className="mt-3">
                              <div>
                                <strong>{i + 1}.</strong>
                                <a
                                  style={{ display: "inline" }}
                                  className="green-btn-text"
                                  href={`listing-detail?id=${val?.auction_id}`}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  {val.title}
                                </a>
                              </div>
                              <div>
                                {val.trader_name && (
                                  <span>
                                    Trader: <b>{val.trader_name}</b>
                                  </span>
                                )}
                              </div>
                              <div>
                                {val.trader_manager && (
                                  <span>
                                    Trader Manager: <b>{val.trader_manager}</b>
                                  </span>
                                )}
                              </div>
                              <div>
                                {val.int_ref && (
                                  <span>
                                    BD: <b>{val.int_ref}</b>
                                  </span>
                                )}
                              </div>
                              <div>
                                {val.bd_manager && (
                                  <span>
                                    BD Manager: <b>{val.bd_manager}</b>
                                  </span>
                                )}
                              </div>
                              <div>
                                <span>
                                  Location:{" "}
                                  <b>
                                    {val.auction_city && `${val.auction_city} , `}
                                    {val.auction_country && (
                                      <a href="javascript:void(0)" className="green-btn-text">
                                        {" "}
                                        {val.auction_country}{" "}
                                      </a>
                                    )}
                                  </b>
                                </span>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </td>
                  <td>
                    {editInternalPeople ? (
                      <div>
                        <EditInternalPeaople
                          auctionId={props.auctionId}
                          openEditInternal={openEditInternal}
                          requestors={requestors}
                          refStart={() => props.parentprops.refStart()}
                          refStop={() => props.parentprops.refStop()}
                          more={more}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="mb-2">
                          {more.trader_name ? (
                            <>
                              <span className="me-2">Trader : </span>
                              <div className="fw-medium">{more.trader_name}</div>
                            </>
                          ) : (
                            <>
                              <span className="me-2">Trader : </span>
                              <div className="fw-medium">None selected</div>
                            </>
                          )}
                        </div>
                        <div className="mb-2">
                          {more.internal_referral && (
                            <>
                              <span className="me-2">Business Development : </span>
                              <div className="fw-medium">{more.internal_referral}</div>
                            </>
                          )}
                        </div>
                        <div className="mb-2">
                          {more.auth_name && (
                            <>
                              <span className="me-2">Created By : </span>
                              <div className="fw-medium">{more.auth_name}</div>
                            </>
                          )}
                        </div>
                        <div className="mb-2">
                          {more.method_origin && (
                            <>
                              <span className="me-2">Method Origin : </span>
                              <div className="fw-medium">{more.method_origin}</div>
                            </>
                          )}
                        </div>
                        <div className="mb-2">
                          {more.individual_origin && (
                            <>
                              <span className="me-2">Individual Origin: </span>
                              <div className="fw-medium">{more.individual_origin}</div>
                            </>
                          )}
                        </div>
                        <div className="mb-2">
                          {more.comm_member_3 && (
                            <>
                              <span className="me-2">Administrator : </span>
                              <div className="fw-medium">{more.comm_member_3}</div>
                            </>
                          )}
                        </div>
                        <div className="mb-2">
                          {more.comm_member_4 && (
                            <>
                              <span className="me-2">Administrator : </span>
                              <div className="fw-medium">{more.comm_member_4}</div>
                            </>
                          )}
                        </div>
                        <div className="mb-2">
                          {more.comm_member_5 && (
                            <>
                              <span className="me-2">Administrator : </span>
                              <div className="fw-medium">{more.comm_member_5}</div>
                            </>
                          )}
                        </div>
                        <div className="mb-2">
                          {more.comm_member_6 && (
                            <>
                              <span className="me-2">Optimizer :</span>
                              <div className="fw-medium">{more.comm_member_6}</div>
                            </>
                          )}
                        </div>
                        <div className="mb-2">
                          {more.comm_member_7 && (
                            <>
                              <span className="me-2">Ext.Referral :</span>
                              <div className="fw-medium">{more.comm_member_7}</div>
                            </>
                          )}
                        </div>
                        <div className="mb-2">
                          {more.outside_agent_name && (
                            <>
                              <span className="me-2">Outside Agent :</span>
                              <div className="fw-medium">{more.outside_agent_name}</div>
                            </>
                          )}
                        </div>
                        <div className="mb-2">
                          {more.mgr_name && (
                            <>
                              <span className="me-2">Trader Manager : </span>
                              <div className="fw-medium">{more.mgr_name}</div>
                            </>
                          )}
                        </div>
                        <div className="mb-2">
                          {more.admin_name && (
                            <>
                              <span className="me-2">BD Manager : </span>
                              <div className="fw-medium">{more.admin_name}</div>
                            </>
                          )}
                        </div>
                        {session.sec_list === 100103 && (
                          <>
                            <div>
                              <button
                                className="btn btn-primary btn-xs-size btn-auto mt-3 mb-2"
                                onClick={() => {
                                  openEditInternal();
                                }}
                              >
                                Edit Internal People
                              </button>
                            </div>
                            <div>
                              <button
                                className="btn btn-primary btn-xs-size btn-auto mb-3"
                                onClick={() => {
                                  getListingApplyTeam(more.auction_id);
                                }}
                              >
                                Apply Team to Other Listing
                              </button>
                            </div>
                            {more?.hasOwnProperty("status_id") &&
                              more.status_id <= 3 &&
                              more?.hasOwnProperty("available_view") && (
                                <div>
                                  <div>Available to View : </div>
                                  <div className="d-flex">
                                    <div class="form-check radio pe-2">
                                      <input
                                        class="form-check-input cursor-pointer"
                                        type="radio"
                                        name={`${props.auctionId}-atv`}
                                        value="yes"
                                        defaultChecked={availableToView}
                                        onChange={(e) => handleAvailabletoV(e)}
                                        id={`${props.auctionId}_yes`}
                                      />
                                      <label class="form-check-label fw-medium" htmlFor={`${props.auctionId}_yes`}>
                                        Yes
                                      </label>
                                    </div>
                                    <div class="form-check radio pe-2">
                                      <input
                                        class="form-check-input cursor-pointer"
                                        type="radio"
                                        name={`${props.auctionId}-atv`}
                                        defaultChecked={!availableToView}
                                        onChange={(e) => handleAvailabletoV(e)}
                                        id={`${props.auctionId}_no`}
                                        value="no"
                                      />
                                      <label class="form-check-label fw-medium" htmlFor={`${props.auctionId}_no`}>
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    )}
                  </td>
                  <td style={{ minWidth: "180px", maxWidth: "240px" }}>
                    <div style={{ width: "230px" }}>
                      <div className="mb-3 d-flex">
                        <div>Potential Profit</div>
                        &nbsp; : &nbsp;
                        <span className="fw-medium">
                          {more.potential_profit
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(more.potential_profit)
                            : "$0.00"}
                        </span>
                      </div>
                      <div className="mb-3 d-flex">
                        <div>Recycle Value </div>
                        &nbsp; : &nbsp;
                        <span className="fw-medium">
                          {more.recycle_value
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(more.recycle_value)
                            : "$0.00"}
                        </span>
                      </div>
                      <div className="mb-3 d-flex">
                        <div className="d-flex">
                          <div>Retail Value </div>
                          <div class="form-check tick-checkbox ms-2">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value={!more.retail_value_feature}
                              checked={priceFeatureData.f_1002}
                              id="latest-activity-retailvalue-price"
                              onChange={(e) => {
                                handleUpdatePriceFeature(e);
                              }}
                              name="f_1002"
                            />
                            <label class="form-check-label" htmlFor="latest-activity-retailvalue-price"></label>
                          </div>
                          : &nbsp;
                        </div>
                        <span className="fw-medium">
                          {more.retail_value
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(more.retail_value)
                            : "$0.00"}
                        </span>
                      </div>
                      <div className="mb-3 d-flex">
                        <div className="d-flex">
                          <div>Origin Wholesale Value </div>
                          <div class="form-check tick-checkbox ms-2">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value={!more.wholesale_value_feature}
                              checked={priceFeatureData.f_1003}
                              id="latest-activity-wholesale-price"
                              onChange={(e) => {
                                handleUpdatePriceFeature(e);
                              }}
                              name="f_1003"
                            />
                            <label class="form-check-label" htmlFor="latest-activity-wholesale-price"></label>
                          </div>
                          : &nbsp;
                        </div>
                        <span className="fw-medium">
                          {more.orig_wholesale_value
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(more.orig_wholesale_value)
                            : "$0.00"}
                        </span>
                      </div>
                      <div className="mb-3 d-flex">
                        <div className="d-flex">
                          <div>Claim Value</div>
                          <div class="form-check tick-checkbox ms-2">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value={!more.claim_value_feature}
                              checked={priceFeatureData.f_1004}
                              id="latest-activity-claim-price"
                              onChange={(e) => {
                                handleUpdatePriceFeature(e);
                              }}
                              name="f_1004"
                            />
                            <label class="form-check-label" htmlFor="latest-activity-claim-price"></label>
                          </div>
                          : &nbsp;
                        </div>
                        <span className="fw-medium">
                          {more.claim_value
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(more.claim_value)
                            : "$0.00"}
                        </span>
                      </div>
                      <div className="mb-3 d-flex">
                        <div>Seller Asking Price </div>
                        <span className="fw-medium">
                          &nbsp; : &nbsp;
                          {more.seller_reserve
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(more.seller_reserve)
                            : "$0.00"}
                        </span>
                      </div>
                      <div className="mb-3 d-flex">
                        <div>Seller Asking Price Percentage &nbsp;</div>:
                        <div className="fw-medium">
                          &nbsp;
                          {more?.orig_wholesale_value > 0
                            ? Math.round((more?.seller_reserve / more?.orig_wholesale_value) * 100) + "%"
                            : "0%"}
                        </div>
                      </div>
                      <div className="mb-3 d-flex">
                        <div className="d-flex">
                          <div>Public Asking Price </div>
                          <div class="form-check tick-checkbox ms-2">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value={!more.asking_price_feature}
                              checked={priceFeatureData.f_1001}
                              id="latest-activity-asking-price"
                              onChange={(e) => {
                                handleUpdatePriceFeature(e);
                              }}
                              name="f_1001"
                            />
                            <label class="form-check-label" htmlFor="latest-activity-asking-price"></label>
                          </div>
                          : &nbsp;
                        </div>
                        <span className="fw-medium">
                          {more?.public_reserve
                            ? "$" + new Intl.NumberFormat("en-US").format(more?.public_reserve)
                            : "$0.00"}
                        </span>
                      </div>
                      <button onClick={() => toggleEditModal()} className="btn btn-primary btn-xs-size btn-auto mt-3">
                        Edit
                      </button>

                      {more?.comp_prices && more?.comp_prices?.length > 0 && (
                        <>
                          <div className="d-flex justify-content-center mt-3">
                            <span>Comparable Prices</span>
                          </div>
                          <div className="dividerTable" />
                          <div class="table-responsive table-custom">
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <td>Amount</td>
                                  <td>Year</td>
                                  <td>Location</td>
                                  <td>Link</td>
                                </tr>
                              </thead>
                              <tbody>
                                {more?.comp_prices?.map((val) => (
                                  <tr>
                                    <td>
                                      {val?.amount
                                        ? "$" +
                                          new Intl.NumberFormat("en-US", {
                                            style: "decimal",
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }).format(val?.amount)
                                        : "$0.00"}
                                    </td>
                                    <td>{val?.year ? val?.year : "N/A"}</td>
                                    <td>{val?.location ? val?.location : "N/A"}</td>
                                    <td>
                                      {val?.link ? (
                                        <a className="green-btn-text" href={val?.link} target="_blank">
                                          Link
                                        </a>
                                      ) : (
                                        "N/A"
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </>
                      )}
                      {more.seller_bid_date && more.seller_bid ? (
                        <>
                          <div className="mt-3 d-flex">
                            <div>Last Bid Amount : </div>
                            <span className="fw-medium">
                              {more.seller_bid
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(more.seller_bid)
                                : "$0.00"}
                            </span>
                          </div>
                          <div className="mt-3 d-flex">
                            <div style={{ minWidth: "fit-content" }}>Last Bid Date : </div>
                            <span className="fw-medium">
                              <DateFormat date={more.seller_bid_date} />
                            </span>
                          </div>
                        </>
                      ) : (
                        <div className="mt-3 d-flex">
                          <div>Last Bid Sent : </div>
                          <span className="fw-medium">
                            <b> &nbsp; None Sent</b>
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="mt-3">
                      {more?.b_paid && (
                        <div>
                          <button className="btn btn-primary btn-xs-size btn-auto w-100 mt-2">Buyer Paid</button>
                        </div>
                      )}
                      {more?.s_paid && (
                        <div>
                          <button className="btn btn-primary btn-xs-size btn-auto w-100 mt-2">Seller Paid</button>
                        </div>
                      )}
                      {more?.signed && (
                        <div>
                          <button className="btn btn-primary btn-xs-size btn-auto w-100 mt-2">Seller Accepted</button>
                        </div>
                      )}
                      {more?.pickedup && (
                        <div>
                          <button className="btn btn-primary btn-xs-size btn-auto w-100 mt-2">Picked Up</button>
                        </div>
                      )}
                    </div>
                  </td>
                  <td style={{ minWidth: "200px", maxWidth: "230px" }}>
                    <div style={{ width: "220px" }}>
                      <div className="d-flex justify-content-end">
                        <button className="btn btn-auto btn-xs-size btn-primary" onClick={handleRefresh}>
                          Refresh
                        </button>
                      </div>
                      {more.topbidders && more.topbidders.length > 0 ? (
                        more.topbidders.map((item, i) => (
                          <div class="col-content-widget" key={i}>
                            <div class="col-content-heaing d-flex flex-wrap align-items-center">
                              <div class="col-content-heaing-count">{i + 1}.</div>
                              <div class="col-content-heaing-txt d-flex flex-wrap align-items-center">
                                <div class="col-content-heaing-logo me-2 d-flex flex-wrap align-items-center">
                                  {item?.dhs_approved && <img class="l-logo-box" src={icon1} alt="" />}
                                  {item.okay && <img class="l-logo-box" src={icon2} alt="" />}
                                  {item?.stop_working && (
                                    <img class="l-logo-box" src={icon3} width="30px" height="30px" alt="" />
                                  )}
                                </div>
                                <img src={extLink} className="me-1 cursor-pointer" alt="External Link" />
                                <a
                                  href="javascript:void(0)"
                                  className="green-btn-text"
                                  onClick={() => {
                                    if (item?.name) {
                                      if (process.env.REACT_APP_MODE == "development") {
                                        window.open(
                                          `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${item?.b_user_id}`,
                                        );
                                      } else {
                                        window.open(
                                          `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${item?.b_user_id}`,
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {item.name ? (item.name === "n/a" ? "N/A" : item.name) : "N/A"}
                                </a>
                              </div>
                            </div>
                            {item.managed_by && (
                              <div>
                                <span>Managed By: </span>
                                <span className="fw-medium">
                                  {item.managed_by ? (item.managed_by === "n/a" ? "N/A" : item.managed_by) : "N/A"}
                                </span>
                                {item.managed_by ? (
                                  item.managed_by === "n/a" || item.managed_by === "N/A" ? (
                                    ""
                                  ) : (
                                    <a href="javascript:void(0)">
                                      <img src={msgSqr} className="ms-1" alt="help" />
                                    </a>
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                            <div class="col-text">{item.job_title ? item.job_title : ""}</div>
                            <div
                              class="col-text cursor-pointer"
                              onClick={() => {
                                if (item?.name) {
                                  if (process.env.REACT_APP_MODE == "development") {
                                    window.open(
                                      `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${item?.b_user_id}`,
                                    );
                                  } else {
                                    window.open(
                                      `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${item?.b_user_id}`,
                                    );
                                  }
                                }
                              }}
                            >
                              {item.company_name ? item.company_name : ""}
                            </div>
                            {/* <div class="col-text">{item.location && item.location?.length > 0 ? item.location[0] : ""}</div> */}
                            <div class="col-text">{item.phone ? item.phone : ""}</div>

                            <div class="col-num-list fw-medium position-relative d-flex">
                              <div class="col-green me-2">
                                {" "}
                                {parseInt(Math.round(formatPercent(item?.ct2, item?.ct3)))}
                                %({item?.ct2 ? item?.ct2 : 0}){" "}
                              </div>
                              <div class="col-gray me-2">
                                {parseInt(Math.round(formatPercent(item?.ct1, item?.ct3)))}
                                %({item?.ct1 ? item?.ct1 : 0}){" "}
                              </div>
                              <div class="col-red">
                                {parseInt(Math.round(formatPercent(item?.ct0, item?.ct3)))}
                                %({item?.ct0 ? item?.ct0 : 0}){" "}
                              </div>
                              <div class="col-mail-icon">
                                <img src="include/images/mail-14x14.svg" alt="" />
                              </div>
                            </div>
                            <div class="col-label">
                              <span class="fw-medium">
                                {item.total_bid
                                  ? "$" +
                                    new Intl.NumberFormat("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(item?.total_bid)
                                  : "$0.00"}
                              </span>
                              {item?.total_bid &&
                                !isNaN(item?.total_bid) &&
                                productDetails?.pipeDetail?.tonnage &&
                                !isNaN(productDetails?.pipeDetail?.tonnage) &&
                                productDetails?.pipeDetail?.tonnage > 0 && (
                                  <span class="fw-normal">
                                    (
                                    {(item?.total_bid / productDetails?.pipeDetail?.tonnage)?.toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) + "/MT"}
                                    )
                                  </span>
                                )}
                              {" at "}
                              {item?.bid_time ? <DateFormat date={item?.bid_time} /> : "N/A"}
                            </div>
                            {item?.seller_bid_date && item?.seller_bid_date != "" && (
                              <div>
                                <span>Submitted: </span>
                                <span class="fw-medium">
                                  {" "}
                                  {item.seller_bid
                                    ? "$" +
                                      new Intl.NumberFormat("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }).format(item?.seller_bid)
                                    : "$0.00"}
                                </span>{" "}
                                at {item?.seller_bid_date ? <DateFormat date={item?.seller_bid_date} /> : ""}
                              </div>
                            )}
                            {item.invoice_id > 0 ? (
                              <div>
                                <span>Invoiced: </span>
                                {item.invoice_paid === 0 ? (
                                  <span class="fw-medium text-danger">Unpaid</span>
                                ) : (
                                  <span class="fw-medium green-text">Paid</span>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            <div>
                              <span>Credit: </span>
                              <span class="fw-medium">
                                {item.credit_amount
                                  ? "$" +
                                    new Intl.NumberFormat("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(item?.credit_amount)
                                  : "$0.00"}
                              </span>
                            </div>
                            <div class="td-button-group d-flex">
                              <img
                                src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${item?.email}`}
                                alt=""
                              />
                            </div>
                            <div class="col-button-group">
                              <div class="col-btn-list d-flex flex-wrap">
                                <div class="col-btn-item">
                                  <a
                                    href="javascript:void(0)"
                                    class="btn btn-auto btn-xs-size btn-primary"
                                    onClick={() => {
                                      if (process.env.REACT_APP_MODE == "development") {
                                        window.open(
                                          `https://admin-dev.salvex.com/admin/_sellers/_mrk_doc_auc/_create.cfm?s_user_id=${item.b_user_id}&aucID=${more.auction_id}`,
                                        );
                                      } else {
                                        window.open(
                                          `https://admin.salvex.com/admin/_sellers/_mrk_doc_auc/_create.cfm?s_user_id=${item.b_user_id}&aucID=${more.auction_id}`,
                                        );
                                      }
                                    }}
                                  >
                                    Send Marketing Email
                                  </a>
                                </div>
                                <div class="col-btn-item">
                                  <a
                                    href="javascript:void(0)"
                                    class="btn btn-auto btn-xs-size btn-primary"
                                    onClick={() => {
                                      if (process.env.REACT_APP_MODE == "development") {
                                        window.open(
                                          `https://admin-dev.salvex.com/listings/reportsAR/index.cfm?aucID=${more.auction_id}&b_id=${item.b_user_id}`,
                                        );
                                      } else {
                                        window.open(
                                          `https://admin.salvex.com/listings/reportsAR/index.cfm?aucID=${more.auction_id}&b_id=${item.b_user_id}`,
                                        );
                                      }
                                    }}
                                  >
                                    Send Report
                                  </a>
                                </div>
                                {item?.invoice_id > 0 && (
                                  <div class="col-btn-item">
                                    <a
                                      class="btn btn-auto btn-xs-size btn-primary"
                                      href={`/invoice/view-invoice?auctionId=${more.auction_id}&id=${item.invoice_id}&date=${item.bid_time}&b_id=${item.b_id}`}
                                      target="_blank"
                                    >
                                      View Invoice
                                    </a>
                                  </div>
                                )}
                                <div class="col-btn-item">
                                  <a
                                    href="javascript:void(0)"
                                    class="btn btn-auto btn-xs-size btn-primary"
                                    onClick={() => {
                                      setBidId(item?.b_id);
                                      toggleActivationModal();
                                    }}
                                  >
                                    Deactivate
                                  </a>
                                </div>
                                <div class="col-btn-item">
                                  {item?.bid_declined_time?.split("")?.length > 0 ? (
                                    <a href="javascript:void(0)" class="btn btn-auto btn-xs-size btn-primary">
                                      Bid Declined {moment(item?.bid_declined_time).format("MM/DD/YY")}
                                    </a>
                                  ) : (
                                    <a
                                      href="javascript:void(0)"
                                      class="btn btn-auto btn-xs-size btn-primary"
                                      onClick={() => {
                                        setDeclineData({
                                          decline: item?.b_id,
                                          new_message: `The offer you have submitted of ${
                                            item?.total_bid > 0
                                              ? "$" +
                                                new Intl.NumberFormat("en-US", {
                                                  style: "decimal",
                                                  minimumFractionDigits: 2,
                                                }).format(item?.total_bid)
                                              : "$0.00"
                                          } has been declined.`,
                                        });
                                        toggleDeclineModal();
                                      }}
                                    >
                                      Decline Bid
                                    </a>
                                  )}
                                </div>
                              </div>

                              {/* <div className="terms mt-2">
                                        {bidId === item.b_id && showTerms && (
                                          <div
                                            class="form-control"
                                            dangerouslySetInnerHTML={{
                                              __html: item?.terms,
                                            }}
                                          />
                                        )}
                                      </div> */}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <span>No bids</span>
                        </div>
                      )}
                      <div class="col-button-group">
                        <div class="col-btn-list d-flex flex-wrap">
                          {more?.revisit_seller_bid_date && (
                            <div>
                              Revisit seller bid on{" "}
                              <span className="fw-medium">
                                {moment(more?.revisit_seller_bid_date).format("MM/DD/YY")}
                              </span>
                            </div>
                          )}
                          <div class="col-btn-item">
                            <a
                              href="javascript:void(0)"
                              class="btn btn-auto btn-xs-size btn-default"
                              onClick={() => {
                                handleRevisitSellerBid();
                              }}
                            >
                              {revisitSellerBid ? "Reinitiate" : "Revisit"} Seller Bid
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td style={{ width: "230px" }}>
                    <div className="mt-3 mb-3">
                      {/* <div class="details-page-widget-block"> */}
                      <div class="page-widget-head d-flex align-items-center justify-content-between shadow p-2 mb-2 bg-white rounded">
                        <div class="d-flex align-items-center">
                          <div class="page-widget-label text-capitalize">Completed Task List</div>
                        </div>
                        <div class="d-flex align-items-center">
                          {showtasks ? (
                            <button
                              className="btn btn-primary btn-xs-size btn-auto"
                              onClick={() => setShowTasks(false)}
                            >
                              Hide
                            </button>
                          ) : (
                            <button className="btn btn-primary btn-xs-size btn-auto" onClick={viewCompletedTasks}>
                              View
                            </button>
                          )}
                        </div>
                      </div>
                      {!showtasks && loadingTasks && (
                        <div
                          class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
                          style={{
                            height: "50px",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <span>Content Loading...</span>
                        </div>
                      )}
                      {showtasks && (
                        <div
                          class="details-page-widget-block"
                          style={{
                            minHeight: "1px",
                            maxHeight: "400px",
                            overflowY: "scroll",
                          }}
                        >
                          <div class="page-widget-head d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                              <div class="page-widget-label text-capitalize">Completed Tasks</div>
                            </div>
                          </div>
                          <div class="page-widget-body mt-3">
                            <div class="table-responsive">
                              <table class="table align-left">
                                <colgroup>
                                  <col style={{ width: "70%" }} />
                                  <col style={{ width: "30%" }} />
                                </colgroup>
                                <thead>
                                  <tr>
                                    <th>Task</th>
                                    <th>Date Completed</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tasks?.map((val, i) => (
                                    <tr key={i}>
                                      <td>
                                        <span class="fw-medium">
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: val?.description,
                                            }}
                                          />
                                        </span>
                                      </td>
                                      <td>
                                        <span class="fw-medium">
                                          <DateFormat date={val?.date_completed} />
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        class="text-card-block-row mt-4"
                        style={{
                          minHeight: "0px",
                          maxHeight: "400px",
                          overflowY: "scroll",
                        }}
                      >
                        {more.tasks &&
                          more.tasks?.length > 0 &&
                          more.tasks?.map((data, i) => (
                            <div key={i} class="text-card-block-column">
                              <div class="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                                <div class="tcbc-header-label align-items-center">
                                  <div class="fw-medium">{data.name01}</div>
                                  <div>
                                    <DateFormat date={data.created} />
                                  </div>
                                </div>
                                <div class="dropdown ">
                                  <a
                                    href="javascript:void(0)"
                                    class="td-a-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img src={More} alt="" />
                                  </a>
                                  <ul
                                    className="dropdown-menu custom-dropdown-menu"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasRight"
                                    aria-controls="offcanvasRight"
                                  >
                                    {data?.task_status_id === 1 ? (
                                      <div class="tcbc-right-box">
                                        {data.member_id == session.member_id || session.member_id == 1 ? (
                                          <li className="menu-item">
                                            <a
                                              class="dropdown-item cursor-pointer"
                                              onClick={() => {
                                                setTaskId(data.chat_id);
                                                toggleDeleteModal();
                                              }}
                                              href="javascript:void(0)"
                                            >
                                              Delete
                                            </a>
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                        {data?.task_id > 0 && data?.task_status_id == 1 && (
                                          <li className="menu-item">
                                            <a
                                              class="dropdown-item cursor-pointer"
                                              onClick={() => {
                                                setStatusData({
                                                  chat_id: data.chat_id,
                                                  status_id: 4,
                                                });
                                                toggleProgressModal();
                                              }}
                                              href="javascript:void(0)"
                                            >
                                              In Progress
                                            </a>
                                          </li>
                                        )}
                                        {data?.task_id > 0 && data?.task_status_id == 1 && (
                                          <li className="menu-item">
                                            <a
                                              type="button"
                                              class="dropdown-item"
                                              href="javascript:void(0)"
                                              onClick={() => {
                                                setRevisitId(data.chat_id);
                                                setStatusData({
                                                  chat_id: data.chat_id,
                                                  status_id: 5,
                                                  revisit_date: data.revisit_date
                                                    ? data.revisit_date
                                                    : moment(Date.now()).format("MM/DD/YYYY"),
                                                  revisit_email: data.revisit_email ? data.revisit_email : false,
                                                });
                                                setRevisit(true);
                                              }}
                                            >
                                              Revisit
                                            </a>
                                          </li>
                                        )}
                                        {data?.task_id > 0 && data?.task_status_id == 1 && (
                                          <li className="menu-item">
                                            <a
                                              href="javascript:void(0)"
                                              class="dropdown-item cursor-pointer"
                                              onClick={() => {
                                                setStatusData({
                                                  chat_id: data.chat_id,
                                                  status_id: 3,
                                                });
                                                toggleCompleteModal();
                                              }}
                                            >
                                              Complete
                                            </a>
                                          </li>
                                        )}
                                      </div>
                                    ) : data?.task_id > 0 && data?.task_status_id === 4 ? (
                                      <div class="tcbc-right-box">
                                        {data?.task_id > 0 && data?.task_status_id === 4 ? (
                                          <li className="menu-item ">
                                            <a class="dropdown-item disabled" style={{ color: "##137c10" }}>
                                              In Progress
                                            </a>
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                        {data?.task_id > 0 && data?.task_status_id === 4 ? (
                                          <li className="menu-item">
                                            <a
                                              class="dropdown-item"
                                              onClick={() => {
                                                setRevisitId(data?.chat_id);
                                                setRevisit(true);
                                                setStatusData({
                                                  chat_id: data?.chat_id,
                                                  status_id: 5,
                                                  revisit_date: data.revisit_date
                                                    ? data.revisit_date
                                                    : moment(Date.now()).format("MM/DD/YYYY"),
                                                  revisit_email: data.revisit_email ? data.revisit_email : false,
                                                });
                                              }}
                                            >
                                              Revisit
                                            </a>
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                        {data?.task_id > 0 && data?.task_status_id === 4 ? (
                                          <li className="menu-item">
                                            <a
                                              class="dropdown-item"
                                              onClick={() => {
                                                setStatusData({
                                                  chat_id: data.chat_id,
                                                  status_id: 3,
                                                });
                                                toggleCompleteModal();
                                              }}
                                            >
                                              Complete
                                            </a>
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </ul>
                                </div>
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data.message,
                                }}
                              />
                              {revisitId === data.chat_id && revisit ? (
                                <>
                                  <div
                                    className="d-flex justify-content-end mt-2 cursor-pointer"
                                    onClick={() => setRevisit(false)}
                                  >
                                    <img src={closeButton} alt="" />
                                  </div>
                                  <div className="d-flex flex-column ">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <DatePicker
                                        disableHighlightToday={true}
                                        className="form-control"
                                        value={statusData.revisit_date}
                                        format="MM/DD/YYYY"
                                        onChange={(e) => {
                                          const values = { ...statusData };
                                          const formatted = moment(e).format("MM/DD/YYYY");
                                          values.revisit_date = formatted;
                                          setStatusData(values);
                                        }}
                                        renderInput={(params) => <TextField {...params} error={false} />}
                                        inputFormat="MM/dd/yyyy"
                                      />
                                    </LocalizationProvider>
                                    <div className="d-flex justify-content-between mt-2">
                                      <div class="form-check dark-tick-checkbox">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="tick2"
                                          onChange={(e) => {
                                            const values = { ...statusData };
                                            values.revisit_email = e.target.checked;
                                            setStatusData(values);
                                          }}
                                        />
                                        <label class="form-check-label" for="tick2"></label>
                                        <span>Email Task on revisit Date?</span>
                                      </div>
                                      <NextButton
                                        label="Save"
                                        classData="btn btn-default"
                                        handleSubmit={handleTaskProcess}
                                        loading={loadingMessage}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          ))}
                      </div>
                      <div class="page-widget-body mt-3">
                        <div class="widget-form-widget">
                          <div class="form-group-15">
                            <Select
                              styles={customStyles1}
                              options={tasksOptions}
                              value={taskOption}
                              placeholder="Select Task"
                              onChange={(e) => {
                                const data = { ...messageData };
                                data.task_type = e.task_type;
                                setMessageData(data);
                                setTaskOption(e);
                              }}
                              getOptionLabel={(option) => option.description}
                              getOptionValue={(option) => option.task_type}
                            />
                          </div>
                          <div class="wfw-label-row w-100 d-flex flex-wrap justify-content-between">
                            <div class="wfw-label w-80 pr-3">
                              <div class="wfw-txt-bold fw-medium">Additional Recipients</div>
                              <p class="wfw-txt">Internal Ref. and Trader already included</p>
                            </div>
                          </div>
                          <div class="form-group-15">
                            <select
                              name="admin_comments"
                              id="admin_comments"
                              multiple
                              style={{ height: "116px", width: "100%" }}
                              onChange={(e) => {
                                const data = { ...messageData };
                                e?.map((s) => {
                                  if (data.to_id.includes(s.member_id)) {
                                    data.to_id.splice(data.to_id.indexOf(s.member_id), 1);
                                  } else {
                                    data.to_id.push(s.member_id);
                                  }
                                });
                                setAdminOption(e);
                                setMessageData(data);
                              }}
                            >
                              {requestors?.map((val) => (
                                <option value={val?.member_id}>{val?.name01}</option>
                              ))}
                            </select>
                            {/* <Select
                    styles={customStyles1}
                    options={requestors}
                    isMulti
                    value={adminOption}
                    placeholder="Select Admin"
                    onChange={(e) => {
                      const data = { ...messageData };
                      e.map((s) => {
                        if (data.to_id.includes(s.member_id)) {
                          data.to_id.splice(data.to_id.indexOf(s.member_id), 1);
                        } else {
                          data.to_id.push(s.member_id);
                        }
                      });
                      setAdminOption(e);
                      setMessageData(data);
                    }}
                    getOptionLabel={(option) => option.name01}
                    getOptionValue={(option) => option.member_id}
                  /> */}
                          </div>
                          <div class="form-group-30">
                            {/* <label htmlFor="" class="form-label fw-medium">
                    yourmail@salvex.com
                  </label> */}
                            <input
                              type="text"
                              placeholder="CC"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                const data = { ...messageData };
                                const val = e.target.value.split(",");
                                data.cc_emails_list = val;
                                setMessageData(data);
                              }}
                              class="form-control white"
                            />
                          </div>
                          <div class="form-group-15">
                            {/* <label htmlFor="" class="form-label fw-medium">
                    Message
                  </label> */}
                            <textarea
                              class="form-control white"
                              id="textarea130x130"
                              value={messageData.message}
                              onChange={(e) => {
                                const data = { ...messageData };
                                data.message = e.target.value;
                                setMessageData(data);
                              }}
                              placeholder="Message"
                            ></textarea>
                          </div>
                          <div class="form-checkbox-group-row d-flex flex-wrap">
                            <div class="form-checkbox-item">
                              <div class="form-check dark-tick-checkbox">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  checked={messageData.send_email}
                                  onChange={(e) => {
                                    const data = { ...messageData };
                                    data.send_email = e.target.checked;
                                    setMessageData(data);
                                  }}
                                  id="moreButtonchatSendmail"
                                  name="sendmail2"
                                  value={messageData.send_email}
                                />
                                <label class="form-check-label fw-medium text-nowrap" htmlFor="moreButtonchatSendmail">
                                  Send mail
                                </label>
                              </div>
                            </div>
                            <div class="form-checkbox-item">
                              <div class="form-check dark-tick-checkbox">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  checked={messageData.all_seller_listings}
                                  onChange={(e) => {
                                    const data = { ...messageData };
                                    data.all_seller_listings = e.target.checked;
                                    setMessageData(data);
                                  }}
                                  id="moreButtonchatpost"
                                  name="posttoall"
                                  value={messageData.all_seller_listings}
                                />
                                <label class="form-check-label fw-medium text-nowrap" htmlFor="moreButtonchatpost">
                                  Post to all seller's listings?
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                            <NextButton
                              label="Post Message"
                              classData="btn btn-default btn-lg"
                              handleSubmit={postMessage}
                              loading={loadingMessage}
                            />
                          </div>
                        </div>
                        <div
                          class="text-card-block-row mt-4"
                          style={{
                            minHeight: "0px",
                            maxHeight: "300px",
                            overflowY: "scroll",
                          }}
                        >
                          {more.chat &&
                            more.chat?.length > 0 &&
                            more.chat?.map((data, i) => (
                              <div key={i} class="text-card-block-column">
                                <div class="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                                  <div class="tcbc-header-label align-items-center">
                                    <div class="fw-medium">{data.name01}</div>
                                    <div>
                                      <DateFormat date={data.created} />
                                    </div>
                                  </div>
                                  <div class="tcbc-right-box">
                                    <button
                                      onClick={() => {
                                        setTaskId(data.chat_id);
                                        toggleDeleteModal();
                                      }}
                                      type="button"
                                      class="delete-button d-flex align-items-center"
                                    >
                                      <img src="include/images/trash18x18.svg" alt="" /> Delete
                                    </button>
                                  </div>
                                </div>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: data.message,
                                  }}
                                ></p>
                              </div>
                            ))}
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </td>
                  <ActivationModal
                    show={showActivationModal}
                    onHide={toggleActivationModal}
                    activation={false}
                    submitHandler={handleActivation}
                    loading={loadingActivation}
                  />
                  {/* <CategoryPreferenacesModal
            show={categoryToggle}
            onHide={toggleCategoryPreferenaces}
            loadingCategory={loadingCategory}
            categoryDropdown={categoryDropdown}
            categoryDropDownData={categoryDropDownData}
            setCategoryDropdown={setCategoryDropdown}
            checkList={checkList}
            setCheckList={setCheckList}
            select={select}
            setSelect={setSelect}
            subCat={subCat}
            setSubCat={setSubCat}
            handleSubmit={handleSubmit}
            categoryData={categoryData}
          /> */}
                  <PushCategories
                    show={categoryToggle}
                    onHide={toggleCategoryPreferenaces}
                    loadingCategory={loadingCategory}
                    categoryDropdown={categoryDropdown}
                    categoryDropDownData={categoryDropDownData}
                    setCategoryDropdown={setCategoryDropdown}
                    checkList={checkList}
                    setCheckList={setCheckList}
                    select={select}
                    setSelect={setSelect}
                    subCat={subCat}
                    setSubCat={setSubCat}
                    handleSubmit={handleSubmit}
                    categoryData={categoryData}
                  />
                  {/* <EditProductDetails
            show={editModal}
            LatestActivity={true}
            MoreButton={true}
            toggleEditModal={() => toggleEditModal()}
            updateProductDetails={props.updateProductDetails}
            // productDetails={productDetails}
            updatePricing={props.updatePricing}
            listingProductDetailReceived={props.listingProductDetailReceived}
            auctionId={props.auctionId}
            setLoadingDetails={setLoadingDetails}
            loadingDetails={loadingDetails}
            data={data}
            refStart={() => props.parentprops.refStart()}
            refStop={() => props.parentprops.refStop()}
          /> */}
                  <Editpricing
                    show={editModal}
                    toggleEditModal={toggleEditModal}
                    data={data}
                    getPricing={props.getPricing}
                    updatePricing={props.updatePricing}
                    getAuctionDetails={props.getAuctionDetails}
                    auctionId={props.auctionId}
                    refStart={props.refStart}
                    refStop={props.refStop}
                  />
                  <MotivationModal
                    show={showMotivationModal}
                    onHide={toggleMotivationModal}
                    data={motivationData}
                    setData={setMotivationData}
                    loading={loadingMotivation}
                    handleSubmit={handleUpdateMotivation}
                  />
                  <DeleteModal
                    show={showDeleteModal}
                    onHide={toggleDeleteModal}
                    loadingDelete={loadingMessage}
                    deleteHandler={deleteChat}
                  />
                  <InProgressModal
                    show={showProgressModal}
                    onHide={toggleProgressModal}
                    loadingDelete={loadingMessage}
                    submitHandler={handleTaskProcess}
                  />
                  <CompleteTaskModal
                    show={showCompleteModal}
                    onHide={toggleCompleteModal}
                    loadingDelete={loadingMessage}
                    submitHandler={handleTaskProcess}
                  />
                  <DeclineModal
                    show={showDeclineModal}
                    onHide={toggleDeclineModal}
                    declineData={declineData}
                    setDeclineData={setDeclineData}
                    submitHandler={handleDecline}
                    loading={loadingDecline}
                  />
                </tr>
              </tbody>
            </table>
          </div>
        </td>
        <ApplyTeam
          show={showApplyTeam}
          onHide={toggleApplyTeam}
          data={applyTeamListing}
          loading={loading}
          setAucIds={setAucIds}
          aucIds={aucIds}
          handleSubmit={handleSaveApplyTeam}
        />
      </tr>
    )
  );
};
const mapDispatchToProps = (dispatch) => ({
  getAuctionDetails: (params, callback) => dispatch(getAuctionDetails(params, callback)),
  getRequestor: (callback) => dispatch(getRequestor(callback)),
  getTaskType: (callback) => dispatch(getTaskType(callback)),
  postMessage: (params, data, callback) => dispatch(postMessage(params, data, callback)),
  getTasks: (params, callback) => dispatch(getTasks(params, callback)),
  getCategoryData: (params, callback) => dispatch(getCategoryData(params, callback)),
  updateCategoryPreference: (params, data, callback) => dispatch(updateCategoryPreference(params, data, callback)),
  updateLocSec: (params, data, callback) => dispatch(updateLocationSecurity(params, data, callback)),
  deactivateBid: (params, data, callback) => dispatch(deactivateBid(params, data, callback)),
  declineBid: (params, data, callback) => dispatch(declineBid(params, data, callback)),
  updatePriceFeature: (params, data, callback) => dispatch(updatePriceFeature(params, data, callback)),
  updateProductDetails: (params, data, callback) => dispatch(updateProductDetails(params, data, callback)),
  updatePricing: (params, data, callback) => dispatch(updatePricing(params, data, callback)),
  listingProductDetailReceived: (data) => dispatch(listingProductDetailReceived(data)),
  productDetails: (params, callback) => dispatch(productDetails(params, callback)),
  getPricing: (params, callback) => dispatch(getPricing(params, callback)),
  availableToView: (params, data, callback) => {
    dispatch(availableToView(params, data, callback));
  },
  getMotivaiton: (params, callback) => dispatch(getMotivation(params, callback)),
  updateMotivation: (params, data, callback) => dispatch(updateMotivation(params, data, callback)),
  deleteMoreButtonChat: (params, data, callback) => dispatch(deleteMoreButtonChat(params, data, callback)),
  updateTaskStatus: (params, data, callback) => dispatch(updateTaskStatus(params, data, callback)),
  getRevisitSellerBid: (params, callback) => dispatch(getRevisitSellerBid(params, callback)),
  updateAuctionField: (params, data, callback) => dispatch(updateAuctionField(params, data, callback)),
  otherAuctions: (data, callback) => dispatch(otherAuctions(data, callback)),
});
const mapStateToProps = (state) => ({
  dashboardData: dashboardData(state),
  payableDetails: payableDetails(state),
  commentsAndNotesDetails: commentsAndNotesDetails(state),
  getListingProductDetail: getListingProductDetail(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MoreButton));
