import React, { useEffect, useState } from "react";
import menuIcon from "../include/images/menu-icon.svg";
import { Accordion } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { clearAllToken, getProfile, setLogOutRedirect } from "../utils.js/localStorageServices";
import { getAllReminderQuestions, getAllUnreadCount, questionDetails } from "../store/questions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../common/alerts/alertError";

const Sidebar = (props) => {
  const [activeKey, setActiveKey] = useState("0");
  const history = useHistory();
  const unreadCount = props?.questionDetails?.allUnreadCount?.data;
  useEffect(() => {
    if (props.showMenu) {
      document.body.classList.add("open-menu");
    } else {
      document.body.classList.remove("open-menu");
      setActiveKey("0");
      props.setShowMenu(false);
    }
  }, [props.showMenu]);

  useEffect(() => {
    props.getAllUnreadCount((res) => {
      if (res.status === 200) {
      } else {
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
    const chatCount = setInterval(() => {
      props.getAllUnreadCount((res) => {
        if (res.status === 200) {
        } else {
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }, 2000 * 60);
    return () => clearInterval(chatCount);
  }, []);

  //Uncomment if needed..
  // useEffect(() => {
  //   if (props.page == "reports") {
  //     if (props.pageCat == "pipe-report" && props.showMenu) {
  //       setActiveKey("2");
  //     }
  //     if (props.pageCat == "login-report" && props.showMenu) {
  //       setActiveKey("2");
  //     }
  //   }
  //   if (props.page == "aviation") {
  //     if (props.pageCat == "procurement-requests" && props.showMenu) {
  //       setActiveKey("99");
  //     }
  //     if (props.pageCat == "order-requests" && props.showMenu) {
  //       setActiveKey("99");
  //     }
  //     if (props.pageCat == "logs" && props.showMenu) {
  //       setActiveKey("99");
  //     }
  //     if (props.pageCat == "listings" && props.showMenu) {
  //       setActiveKey("99");
  //     }
  //     if (props.pageCat == "aviationModel" && props.showMenu) {
  //       setActiveKey("99");
  //     }
  //     if (props.pageCat == "aviationCondition" && props.showMenu) {
  //       setActiveKey("99");
  //     }
  //     if (props.pageCat == "planePartType" && props.showMenu) {
  //       setActiveKey("99");
  //     }
  //     if (props.pageCat == "planeModel" && props.showMenu) {
  //       setActiveKey("99");
  //     }
  //     if (props.pageCat == "planePartManufacturer" && props.showMenu) {
  //       setActiveKey("99");
  //     }
  //     if (props.pageCat == "planeManufacturer" && props.showMenu) {
  //       setActiveKey("99");
  //     }
  //     if (props.pageCat == "planeLocation" && props.showMenu) {
  //       setActiveKey("99");
  //     }
  //   }
  //   if (props.page == "accounting") {
  //     if (props.pageCat == "investments" && props.showMenu) {
  //       setActiveKey("100");
  //     }
  //     if (props.pageCat == "commission" && props.showMenu) {
  //       setActiveKey("100");
  //     }
  //   }

  //   if (props?.page == "legal") {
  //     if (props?.pageCat == "contractor" && props?.showMenu) {
  //       setActiveKey("101");
  //     }
  //     if (props?.pageCat == "enterprise" && props?.showMenu) {
  //       setActiveKey("101");
  //     }
  //   }

  //   if (props?.page == "mfrAndModel") {
  //     if (props?.pageCat == "mfr" && props?.showMenu) {
  //       setActiveKey("21");
  //     }
  //     if (props?.pageCat == "model" && props?.showMenu) {
  //       setActiveKey("21");
  //     }
  //   }
  // }, [props.page, props.showMenu]);

  const member = getProfile();

  const isAviationAccessible =
    member && member?.accessLevels && member?.accessLevels?.aviation_parts_admin_access ? true : false;

  const isInvestmentAccessible =
    member && member?.accessLevels && member?.accessLevels?.employee_investment_admin_access
      ? member?.accessLevels?.employee_investment_admin_access
      : false;

  const isSystemAdmin = member?.accessLevels?.system_admin ? true : false;

  const isSellerListingVisible = member?.accessLevels?.display_seller_on_listing_request ? true : false;

  const isSalvexEI = member?.accessLevels?.salvex_ei ? true : false;

  const docTemplateAccess = member?.accessLevels?.document_template_access ? true : false;

  const firstName = member && member.name01 && member.name01.split(" ")[0];

  useEffect(() => {
    props?.getAllReminderQuestions({ page: 1, limit: 50, is_read: 0 }, (res) => {
      if (res?.status === 200) {
      }
    });
  }, []);

  return (
    <div class={`side-nav position-relative ${props?.page === "chats" ? "chat-side" : ""} desktop-nav`}>
      <div class="profile-area d-flex flex-wrap align-items-center" onClick={() => props.setShowMenu(!props.showMenu)}>
        <div class="profile-img d-flex align-items-center justify-content-center">
          <img src={menuIcon} alt="" />
        </div>
        <div style={{ display: "none" }} id="popover-d">
          <div class="popover " role="tooltip">
            <p>Amet, turpis dui pellentesque sed eget aliquam quis felis. Et interdum</p>
          </div>
        </div>
        <div class="profile-text-box">
          <h4>Welcome, {firstName ? firstName : ""}!</h4>
        </div>
      </div>
      <div class="side-nav-inside hide-scrollbar" style={{ overflowY: "scroll" }}>
        <Accordion
          className="accordion"
          id="sideNav"
          defaultActiveKey={[activeKey]}
          activeKey={[activeKey]}
          onClick={(e) => e.preventDefault()}
        >
          <Accordion.Item eventKey="0" className="accordion-item" onClick={() => history.push("/home")}>
            <Accordion.Header className="accordion-header side-nav-accordion-header">
              <a class="accordion-button side-nav-accordion-button" href="/home">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H15"
                    stroke={props.page === "Home" ? "#47ad1d" : "black"}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 22V12H15V22"
                    stroke={props.page === "Home" ? "#47ad1d" : "black"}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span
                  className={props.page == "Home" ? "active-menu" : "deafult-black-hover-green"}
                  style={{ paddingBottom: "2px", marginTop: "2px" }}
                  onClick={() => history.push("/home")}
                >
                  Home
                </span>
              </a>
            </Accordion.Header>
          </Accordion.Item>
          <Accordion.Item eventKey="0" className="accordion-item" onClick={() => history.push("/")}>
            <Accordion.Header className="accordion-header side-nav-accordion-header">
              <a class="accordion-button side-nav-accordion-button" href="/">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                    stroke={props.page == "Listings" ? "#47ad1d " : "black"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 21.0004L16.65 16.6504"
                    stroke={props.page == "Listings" ? "#47ad1d " : "black"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span
                  className={props.page == "Listings" ? "active-menu" : "deafult-black-hover-green"}
                  style={{ paddingBottom: "2px", marginTop: "2px" }}
                  onClick={() => history.push("/")}
                >
                  Listings
                </span>
              </a>
            </Accordion.Header>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="accordion-item" onClick={() => history.push("/users")}>
            <Accordion.Header className="accordion-header side-nav-accordion-header">
              <a class="accordion-button collapsed side-nav-accordion-button" href="/users">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
                    stroke={props.page == "Users" ? "#47ad1d " : "black"}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
                    stroke={props.page == "Users" ? "#47ad1d " : "black"}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13"
                    stroke={props.page == "Users" ? "#47ad1d " : "black"}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89318 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88"
                    stroke={props.page == "Users" ? "#47ad1d " : "black"}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span
                  className={props.page == "Users" ? "active-menu" : "deafult-black-hover-green"}
                  onClick={() => history.push("/users")}
                >
                  Users
                </span>
              </a>
            </Accordion.Header>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="accordion-item" onClick={() => history.push("/referrals")}>
            <Accordion.Header className="accordion-header side-nav-accordion-header">
              <a class="accordion-button collapsed side-nav-accordion-button" href="/referrals">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3 18.9294V17.1595C3 16.2206 3.37295 15.3203 4.03679 14.6564C4.70064 13.9926 5.60101 13.6196 6.53984 13.6196H9.5"
                    stroke={props.page == "Referrals" ? "#47ad1d " : "black"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.0796 10.0797C12.0346 10.0797 13.6195 8.49484 13.6195 6.53984C13.6195 4.58484 12.0346 3 10.0796 3C8.12463 3 6.53979 4.58484 6.53979 6.53984C6.53979 8.49484 8.12463 10.0797 10.0796 10.0797Z"
                    stroke={props.page == "Referrals" ? "#47ad1d " : "black"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.4894 10.052L14.4789 11.6074L16.0343 13.6179"
                    stroke={props.page == "Referrals" ? "#47ad1d " : "black"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.831 21.5718L18.8415 20.0164L17.2861 18.0059"
                    stroke={props.page == "Referrals" ? "#47ad1d " : "black"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.8664 17.8791C20.2152 17.3376 20.4211 16.7166 20.4649 16.074C20.5088 15.4314 20.3891 14.7881 20.1172 14.2043C19.8452 13.6205 19.4298 13.115 18.9096 12.7352C18.3895 12.3553 17.7816 12.1134 17.1426 12.032L14.4788 11.6075M18.8417 20.0163L16.1778 19.5918C15.5389 19.5104 14.931 19.2685 14.4109 18.8886C13.8907 18.5088 13.4753 18.0033 13.2033 17.4195C12.9313 16.8356 12.8117 16.1924 12.8555 15.5498C12.8994 14.9072 13.1053 14.2862 13.4541 13.7447"
                    stroke={props.page == "Referrals" ? "#47ad1d " : "black"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span
                  className={props.page == "Referrals" ? "active-menu" : "deafult-black-hover-green"}
                  onClick={() => history.push("/referrals")}
                >
                  All Referrals
                </span>
              </a>
            </Accordion.Header>
          </Accordion.Item>
          {isAviationAccessible && (
            <Accordion.Item
              eventKey="99"
              className="accordion-item dropdown"
              onClick={() => {
                if (!props.showMenu) {
                  props.setShowMenu(true);
                }
                if (activeKey == "99") {
                  setActiveKey("0");
                } else {
                  setActiveKey("99");
                }
              }}
            >
              <Accordion.Header class="accordion-header side-nav-accordion-header">
                <div class="accordion-button collapsed side-nav-accordion-button">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_5550_58121)">
                      <path
                        d="M19.6441 7.53767L16.4893 10.5L19.4893 18.75L17.2393 21L12.7573 14.4394L10.4893 16.5V18.75L8.23926 21L6.90872 17.0761L2.98926 15.75L5.23926 13.5H7.48926L9.73926 11.25L2.98926 6.74998L5.23926 4.49998L13.4893 7.49998L16.4621 4.35569L16.3983 4.40899C16.6071 4.19968 16.8552 4.03359 17.1283 3.92021C17.4013 3.80683 17.6941 3.74839 17.9898 3.74823C18.2855 3.74807 18.5783 3.80619 18.8515 3.91926C19.1247 4.03234 19.3729 4.19816 19.582 4.40724C19.7911 4.61632 19.9569 4.86456 20.07 5.13776C20.183 5.41097 20.2412 5.70379 20.241 5.99947C20.2408 6.29515 20.1824 6.5879 20.069 6.86098C19.9556 7.13406 19.7896 7.38212 19.5802 7.59097L19.6441 7.53767Z"
                        stroke={props.page == "aviation" ? "#47ad1d " : "black"}
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5550_58121">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <span
                    className={
                      props.page == "aviation" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                    }
                  >
                    Aviation
                  </span>
                  <svg
                    class="chevron-down-icon"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5.32812 7.5L10.3281 12.5L15.3281 7.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </Accordion.Header>
              <Accordion.Body className="accordion-body" onEnter={() => props.setShowMenu(true)}>
                <ul>
                  <li>
                    <a
                      href="/aviation/aircraft-parts"
                      className={
                        props.pageCat == "listings" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/aviation/aircraft-parts")}
                    >
                      Aircraft Parts
                    </a>
                  </li>
                  <li>
                    <a
                      href="/aviation/archived-aircraft-parts"
                      className={
                        props.pageCat == "archivedListings"
                          ? "active-menu pb-1 mt-1"
                          : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/aviation/archived-aircraft-parts")}
                    >
                      Archived Aircraft Parts
                    </a>
                  </li>
                  <li>
                    <a
                      href="/aviation/procurement-requests"
                      className={
                        props.pageCat == "procurement-requests"
                          ? "active-menu pb-1 mt-1"
                          : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/aviation/procurement-requests")}
                    >
                      Procurement Requests
                    </a>
                  </li>
                  <li>
                    <a
                      href="/aviation/order-requests"
                      className={props.pageCat == "order-requests" ? "active-menu" : "deafult-black-hover-green"}
                      onClick={() => history.push("/aviation/order-requests")}
                    >
                      RFQ
                    </a>
                  </li>
                  <li>
                    <a
                      href="/aviation/reminder-parts"
                      className={props.pageCat == "reminder-parts" ? "active-menu" : "deafult-black-hover-green"}
                      onClick={() => history.push("/aviation/reminder-parts")}
                    >
                      Reminder Parts
                    </a>
                  </li>
                  <li>
                    <a
                      href="/aviation/logs"
                      className={props.pageCat == "logs" ? "active-menu" : "deafult-black-hover-green"}
                      onClick={() => history.push("/aviation/logs")}
                    >
                      Logs
                    </a>
                  </li>
                  <li>
                    <a
                      href="/aviation/aviation-models"
                      className={
                        props.pageCat == "aviationModel"
                          ? "active-menu pb-1 mt-1"
                          : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/aviation/aviation-models")}
                    >
                      Aviation Models
                    </a>
                  </li>
                  <li>
                    <a
                      href="/aviation/aviation-condition"
                      className={
                        props.pageCat == "aviationCondition"
                          ? "active-menu pb-1 mt-1"
                          : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/aviation/aviation-condition")}
                    >
                      Aviation Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      href="/aviation/plane-part-type"
                      className={
                        props.pageCat == "planePartType"
                          ? "active-menu pb-1 mt-1"
                          : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/aviation/plane-part-type")}
                    >
                      Plane Part Type
                    </a>
                  </li>
                  <li>
                    <a
                      href="/aviation/plane-model"
                      className={
                        props.pageCat == "planeModel" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/aviation/plane-model")}
                    >
                      Plane Model
                    </a>
                  </li>
                  <li>
                    <a
                      href="/aviation/plane-part-manufacturer"
                      className={
                        props.pageCat == "planePartManufacturer"
                          ? "active-menu pb-1 mt-1"
                          : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/aviation/plane-part-manufacturer")}
                    >
                      Plane Part Manufacturer
                    </a>
                  </li>
                  <li>
                    <a
                      href="/aviation/plane-manufacturer"
                      className={
                        props.pageCat == "planeManufacturer"
                          ? "active-menu pb-1 mt-1"
                          : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/aviation/plane-manufacturer")}
                    >
                      Plane Manufacturer
                    </a>
                  </li>
                  <li>
                    <a
                      href="/aviation/plane-location"
                      className={
                        props.pageCat == "planeLocation"
                          ? "active-menu pb-1 mt-1"
                          : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/aviation/plane-location")}
                    >
                      Plane Location
                    </a>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          )}
          <Accordion.Item
            eventKey="109"
            className="accordion-item dropdown"
            onClick={() => {
              if (!props.showMenu) {
                props.setShowMenu(true);
              }
              if (activeKey == "109") {
                setActiveKey("0");
              } else {
                setActiveKey("109");
              }
            }}
          >
            <Accordion.Header class="accordion-header side-nav-accordion-header">
              <div class="accordion-button collapsed side-nav-accordion-button">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.43499 9.53322C7.95252 8.79386 9.04748 8.79386 9.56501 9.53322L9.71637 9.74945C10.0062 10.1635 10.5097 10.372 11.0073 10.2842L11.2673 10.2383C12.156 10.0815 12.9303 10.8557 12.7734 11.7445L12.7275 12.0044C12.6397 12.5021 12.8483 13.0055 13.2623 13.2953L13.4785 13.4467C14.2179 13.9642 14.2179 15.0592 13.4785 15.5767L13.2623 15.7281C12.8483 16.0179 12.6397 16.5214 12.7275 17.019L12.7734 17.279C12.9303 18.1677 12.156 18.942 11.2673 18.7851L11.0073 18.7393C10.5097 18.6514 10.0062 18.86 9.71637 19.274L9.56501 19.4902C9.04748 20.2296 7.95252 20.2296 7.43499 19.4902L7.28363 19.274C6.99383 18.86 6.49035 18.6514 5.99267 18.7393L5.73275 18.7851C4.84399 18.942 4.06974 18.1677 4.22659 17.279L4.27246 17.019C4.3603 16.5214 4.15175 16.0179 3.73773 15.7281L3.5215 15.5767C2.78214 15.0592 2.78214 13.9642 3.5215 13.4467L3.73773 13.2953C4.15175 13.0055 4.3603 12.5021 4.27246 12.0044L4.22659 11.7445C4.06974 10.8557 4.84399 10.0815 5.73275 10.2383L5.99267 10.2842C6.49035 10.372 6.99383 10.1635 7.28363 9.74945L7.43499 9.53322Z"
                    stroke={props.page == "parts" ? "#47ad1d " : "black"}
                  />
                  <path
                    d="M8.5 16C9.32843 16 10 15.3284 10 14.5C10 13.6716 9.32843 13 8.5 13C7.67157 13 7 13.6716 7 14.5C7 15.3284 7.67157 16 8.5 16Z"
                    stroke={props.page == "parts" ? "#47ad1d " : "black"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.8957 4.37368C15.1093 3.78653 15.8416 3.59033 16.3201 3.99201L16.4601 4.10949C16.7281 4.33443 17.1021 4.38367 17.4192 4.23576L17.5848 4.15851C18.151 3.89437 18.8075 4.2734 18.8619 4.89583L18.8778 5.07786C18.9082 5.42641 19.1379 5.72572 19.4667 5.84533L19.6384 5.90781C20.2255 6.12141 20.4217 6.85363 20.02 7.33219L19.9026 7.47215C19.6776 7.74013 19.6284 8.11419 19.7763 8.43125L19.8535 8.59685C20.1177 9.16307 19.7387 9.81956 19.1162 9.87392L18.9342 9.88982C18.5857 9.92026 18.2863 10.1499 18.1667 10.4787L18.1043 10.6504C17.8906 11.2376 17.1584 11.4338 16.6799 11.0321L16.5399 10.9146C16.2719 10.6897 15.8979 10.6404 15.5808 10.7884L15.4152 10.8656C14.849 11.1297 14.1925 10.7507 14.1381 10.1283L14.1222 9.94625C14.0918 9.59771 13.8621 9.29839 13.5333 9.17878L13.3616 9.11631C12.7745 8.90271 12.5783 8.17049 12.98 7.69193L13.0974 7.55196C13.3224 7.28398 13.3716 6.90993 13.2237 6.59286L13.1465 6.42727C12.8823 5.86105 13.2613 5.20455 13.8838 5.1502L14.0658 5.1343C14.4143 5.10386 14.7137 4.87419 14.8333 4.5454L14.8957 4.37368Z"
                    stroke={props.page == "parts" ? "#47ad1d " : "black"}
                  />
                  <path
                    d="M16.7328 8.38073C17.2129 8.25208 17.4978 7.75858 17.3692 7.27846C17.2405 6.79834 16.747 6.51342 16.2669 6.64206C15.7868 6.77071 15.5019 7.26421 15.6305 7.74433C15.7592 8.22445 16.2527 8.50938 16.7328 8.38073Z"
                    stroke={props.page == "parts" ? "#47ad1d " : "black"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span
                  className={props.page == "parts" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"}
                >
                  Parts
                </span>
                <svg
                  class="chevron-down-icon"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.32812 7.5L10.3281 12.5L15.3281 7.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </Accordion.Header>
            <Accordion.Body className="accordion-body" onEnter={() => props.setShowMenu(true)}>
              <ul>
                <li>
                  <a
                    href="/parts/"
                    className={
                      props.pageCat == "genericParts" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                    }
                    onClick={() => history.push("/parts/")}
                  >
                    Parts
                  </a>
                </li>
                <li>
                  <a
                    href="/parts/archived"
                    className={
                      props.pageCat == "archivedGenericParts"
                        ? "active-menu pb-1 mt-1"
                        : "deafult-black-hover-green pb-1 mt-1"
                    }
                    onClick={() => history.push("/parts/archived")}
                  >
                    Archived Parts
                  </a>
                </li>
                <li>
                  <a
                    href="/parts/procurement-requests"
                    className={
                      props.pageCat == "parts-procurement-requests"
                        ? "active-menu pb-1 mt-1"
                        : "deafult-black-hover-green pb-1 mt-1"
                    }
                    onClick={() => history.push("/parts/procurement-requests")}
                  >
                    Procurement Requests
                  </a>
                </li>
                <li>
                  <a
                    href="/parts/order-requests"
                    className={props.pageCat == "partRfq" ? "active-menu" : "deafult-black-hover-green"}
                    onClick={() => history.push("/parts/order-requests")}
                  >
                    RFQ
                  </a>
                </li>
                <li>
                  <a
                    href="/parts/logs"
                    className={props.pageCat == "partLogs" ? "active-menu" : "deafult-black-hover-green"}
                    onClick={() => history.push("/parts/logs")}
                  >
                    Logs
                  </a>
                </li>
                <li>
                  <a
                    href="/parts/models"
                    className={
                      props.pageCat == "partsModel" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                    }
                    onClick={() => history.push("/parts/models")}
                  >
                    Models
                  </a>
                </li>
                <li>
                  <a
                    href="/parts/condition"
                    className={
                      props.pageCat == "partCondition" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                    }
                    onClick={() => history.push("/parts/condition")}
                  >
                    Conditions
                  </a>
                </li>

                <li>
                  <a
                    href="/parts/part-manufacturer"
                    className={
                      props.pageCat == "partManufacturer"
                        ? "active-menu pb-1 mt-1"
                        : "deafult-black-hover-green pb-1 mt-1"
                    }
                    onClick={() => history.push("/parts/part-manufacturer")}
                  >
                    Part Manufacturer
                  </a>
                </li>

                <li>
                  <a
                    href="/parts/part-locations"
                    className={
                      props.pageCat == "partLocation" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                    }
                    onClick={() => history.push("/parts/part-locations")}
                  >
                    Part Location
                  </a>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="2"
            className="accordion-item dropdown"
            onClick={() => {
              if (!props.showMenu) {
                props.setShowMenu(true);
              }
              if (activeKey == "2") {
                setActiveKey("0");
              } else {
                setActiveKey("2");
              }
            }}
          >
            <Accordion.Header class="accordion-header side-nav-accordion-header">
              <div class="accordion-button collapsed side-nav-accordion-button">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18 20V10"
                    stroke={props.page == "reports" ? "#47ad1d " : "black"}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 20V4"
                    stroke={props.page == "reports" ? "#47ad1d " : "black"}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6 20V14"
                    stroke={props.page == "reports" ? "#47ad1d " : "black"}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span
                  className={props.page == "reports" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"}
                >
                  Reports
                </span>
                <svg
                  class="chevron-down-icon"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.32812 7.5L10.3281 12.5L15.3281 7.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </Accordion.Header>
            <Accordion.Body className="accordion-body" onEnter={() => props.setShowMenu(true)}>
              <ul>
                {/* <li>
                  <a
                    href="javascript:void(0)"
                    className={
                      props.pageCat == "registration-report"
                        ? "active-menu pb-1 mt-1"
                        : "deafult-black-hover-green pb-1 mt-1"
                    }
                    onClick={() => history.push("/user-registration-report")}
                  >
                    User Registration Report
                  </a>
                </li> */}
                <li>
                  <a
                    href="/pipe-access-report"
                    className={props.pageCat == "pipe-report" ? "active-menu" : "deafult-black-hover-green"}
                    onClick={() => history.push("/pipe-access-report")}
                  >
                    Pipe Access Report
                  </a>
                </li>
                <li>
                  <a
                    href="/buyer-arr-email-stats"
                    className={props.pageCat == "barr-email-stats" ? "active-menu" : "deafult-black-hover-green"}
                    onClick={() => history.push("/buyer-arr-email-stats")}
                  >
                    Buyer ARR Email Stats
                  </a>
                </li>
                {/* <li>
                  <a
                    href="javascript:void(0)"
                    className={
                      props.pageCat == "login-report"
                        ? "active-menu"
                        : "deafult-black-hover-green"
                    }
                    onClick={() => history.push("/login-report")}
                  >
                    User Login Report
                  </a>
                </li> */}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          {isInvestmentAccessible && (
            <Accordion.Item
              eventKey="100"
              className="accordion-item dropdown"
              onClick={() => {
                if (!props.showMenu) {
                  props.setShowMenu(true);
                }
                if (activeKey == "100") {
                  setActiveKey("0");
                } else {
                  setActiveKey("100");
                }
              }}
            >
              <Accordion.Header class="accordion-header side-nav-accordion-header">
                <div class="accordion-button collapsed side-nav-accordion-button">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2 14.5L5.2015 12.7214C6.01976 12.2668 7.00331 12.2202 7.86089 12.5954L13.3374 14.9914C13.9696 15.268 14.2411 16.0178 13.9325 16.635V16.635C13.6798 17.1404 13.11 17.4025 12.5618 17.2654L7.5 16M2 19.5L10.4836 21.1967C11.4593 21.3919 12.4728 21.2163 13.3261 20.7044L21.417 15.8498C21.7255 15.6647 21.8 15.2501 21.5753 14.9691V14.9691C20.6565 13.8206 19.0601 13.4699 17.7445 14.1277L16 15"
                      stroke={props.page == "accounting" ? "#47ad1d " : "black"}
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M15 2V13"
                      stroke={props.page == "accounting" ? "#47ad1d " : "black"}
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.5 4H13.75C13.2859 4 12.8408 4.18437 12.5126 4.51256C12.1844 4.84075 12 5.28587 12 5.75C12 6.21413 12.1844 6.65925 12.5126 6.98744C12.8408 7.31563 13.2859 7.5 13.75 7.5H16.25C16.7141 7.5 17.1592 7.68437 17.4874 8.01256C17.8156 8.34075 18 8.78587 18 9.25C18 9.71413 17.8156 10.1592 17.4874 10.4874C17.1592 10.8156 16.7141 11 16.25 11H12"
                      stroke={props.page == "accounting" ? "#47ad1d " : "black"}
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span
                    className={
                      props.page == "accounting" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                    }
                  >
                    Accounting
                  </span>
                  <svg
                    class="chevron-down-icon"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5.32812 7.5L10.3281 12.5L15.3281 7.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </Accordion.Header>
              <Accordion.Body className="accordion-body" onEnter={() => props.setShowMenu(true)}>
                <ul>
                  <li>
                    <a
                      href="/accounting/employee-investments"
                      className={
                        props.pageCat == "investments" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/accounting/employee-investments")}
                    >
                      Employee Investments
                    </a>
                  </li>
                  <li>
                    <a
                      href="/accounting/commission"
                      className={
                        props.pageCat == "commission" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/accounting/commission")}
                    >
                      Commissions
                    </a>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          )}
          <Accordion.Item
            eventKey="101"
            className="accordion-item dropdown"
            onClick={() => {
              if (!props.showMenu) {
                props.setShowMenu(true);
              }
              if (activeKey == "101") {
                setActiveKey("0");
              } else {
                setActiveKey("101");
              }
            }}
          >
            <Accordion.Header class="accordion-header side-nav-accordion-header">
              <div class="accordion-button collapsed side-nav-accordion-button">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6 7.5V4.7C6 4.24913 6.18437 3.81673 6.51256 3.49792C6.84075 3.17911 7.28587 3 7.75 3H14.75H18C19.1046 3 20 3.89543 20 5V8.1V18.3C20 18.7509 19.8156 19.1833 19.4874 19.5021C19.1592 19.8209 18.7141 20 18.25 20H12"
                    stroke={props.page == "legal" ? "#47ad1d " : "black"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 11.75C16.4142 11.75 16.75 11.4142 16.75 11C16.75 10.5858 16.4142 10.25 16 10.25V11.75ZM16 10.25H9.5V11.75H16V10.25Z"
                    stroke={props.page == "legal" ? "#47ad1d " : "black"}
                  />
                  <path
                    d="M16 15.75C16.4142 15.75 16.75 15.4142 16.75 15C16.75 14.5858 16.4142 14.25 16 14.25V15.75ZM16 14.25H11V15.75H16V14.25Z"
                    stroke={props.page == "legal" ? "#47ad1d " : "black"}
                  />
                  <path
                    d="M12 7H11H10"
                    stroke={props.page == "legal" ? "#47ad1d " : "black"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7 18C9.20914 18 11 16.2091 11 14C11 11.7909 9.20914 10 7 10C4.79086 10 3 11.7909 3 14C3 16.2091 4.79086 18 7 18Z"
                    stroke={props.page == "legal" ? "#47ad1d " : "black"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 17V22L7 20.6364L9 22V17"
                    stroke={props.page == "legal" ? "#47ad1d " : "black"}
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  />
                </svg>

                <span
                  className={props.page == "legal" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"}
                >
                  Legal
                </span>
                <svg
                  class="chevron-down-icon"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.32812 7.5L10.3281 12.5L15.3281 7.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </Accordion.Header>
            <Accordion.Body className="accordion-body" onEnter={() => props.setShowMenu(true)}>
              <ul>
                <li>
                  <a
                    href="/legal/contracts"
                    className={
                      props.pageCat == "contractor" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                    }
                    onClick={() => history.push("/legal/contracts")}
                  >
                    Contracts
                  </a>
                </li>
                <li>
                  <a
                    href="/legal/enterprise-contracts"
                    className={
                      props.pageCat == "enterprise" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                    }
                    onClick={() => history.push("/legal/enterprise-contracts")}
                  >
                    Enterprise Contracts
                  </a>
                </li>
                <li>
                  <a
                    href="/legal/listing-agreement"
                    className={
                      props.pageCat == "agreement" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                    }
                    onClick={() => history.push("/legal/listing-agreement")}
                  >
                    Listing Agreements
                  </a>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          {isSystemAdmin && (
            <Accordion.Item
              eventKey="20"
              className="accordion-item dropdown"
              onClick={() => {
                if (!props.showMenu) {
                  props.setShowMenu(true);
                }
                if (activeKey == "20") {
                  setActiveKey("0");
                } else {
                  setActiveKey("20");
                }
              }}
            >
              <Accordion.Header class="accordion-header side-nav-accordion-header">
                <div class="accordion-button collapsed side-nav-accordion-button">
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19.4759 12.9283V9.33321L13.1427 3H6.80949C6.32958 3 5.86933 3.19064 5.52999 3.52999C5.19064 3.86933 5 4.32958 5 4.80949V19.2854C5 19.7653 5.19064 20.2255 5.52999 20.5649C5.86933 20.9042 6.32958 21.0949 6.80949 21.0949H8.9143"
                      stroke={props.page == "arr" ? "#47ad1d " : "black"}
                      stroke-width="1.35712"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.1426 3V9.33321H19.4758"
                      stroke={props.page == "arr" ? "#47ad1d " : "black"}
                      stroke-width="1.35712"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.81909 14.2847V16.9989H12.5333"
                      stroke={props.page == "arr" ? "#47ad1d " : "black"}
                      stroke-width="1.35712"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19.7711 21.5228V18.8086H17.0569"
                      stroke={props.page == "arr" ? "#47ad1d " : "black"}
                      stroke-width="1.35712"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.6358 16.5462C18.4064 15.8978 18.0165 15.3182 17.5024 14.8613C16.9884 14.4044 16.367 14.0852 15.6962 13.9334C15.0254 13.7816 14.3271 13.8022 13.6664 13.9933C13.0058 14.1844 12.4043 14.5398 11.9181 15.0262L9.81909 16.9985M19.7713 18.808L17.6723 20.7804C17.1861 21.2668 16.5846 21.6221 15.9239 21.8132C15.2633 22.0043 14.565 22.025 13.8942 21.8732C13.2234 21.7214 12.602 21.4022 12.0879 20.9453C11.5739 20.4884 11.184 19.9087 10.9545 19.2604"
                      stroke={props.page == "arr" ? "#47ad1d " : "black"}
                      stroke-width="1.35712"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span
                    className={props.page == "arr" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"}
                  >
                    ARR
                  </span>
                  <svg
                    class="chevron-down-icon"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5.32812 7.5L10.3281 12.5L15.3281 7.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </Accordion.Header>
              <Accordion.Body className="accordion-body" onEnter={() => props.setShowMenu(true)}>
                <ul>
                  <li>
                    <a
                      href="/settings/seller-asset-recovery-report/listings"
                      className={
                        props.pageCat == "assetReport" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/settings/seller-asset-recovery-report/listings")}
                    >
                      Seller ARR Listings
                    </a>
                  </li>
                  <li>
                    <a
                      href="/settings/listing-sellers"
                      className={
                        props.pageCat == "sellerListings"
                          ? "active-menu pb-1 mt-1"
                          : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/settings/listing-sellers")}
                    >
                      ARR Sellers
                    </a>
                  </li>
                  <li>
                    <a
                      href="/settings/listing-buyers"
                      className={
                        props.pageCat == "buyerListings"
                          ? "active-menu pb-1 mt-1"
                          : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/settings/listing-buyers")}
                    >
                      ARR Buyers
                    </a>
                  </li>
                  <li>
                    <a
                      href="/settings/key-buyers"
                      className={
                        props.pageCat == "keyBuyers" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/settings/key-buyers")}
                    >
                      Previously Active Buyers
                    </a>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          )}
          {isSalvexEI && (
            <Accordion.Item
              eventKey="22"
              className="accordion-item dropdown"
              onClick={() => {
                if (!props.showMenu) {
                  props.setShowMenu(true);
                }
                if (activeKey == "22") {
                  setActiveKey("0");
                } else {
                  setActiveKey("22");
                }
              }}
            >
              <Accordion.Header class="accordion-header side-nav-accordion-header">
                <div class="accordion-button collapsed side-nav-accordion-button">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                      stroke={props.page == "salvexEI" ? "#47ad1d " : "black"}
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.8999 8.5H8.3999V12.5M8.3999 12.5V16H12.8999M8.3999 12.5H12.3999"
                      stroke={props.page == "salvexEI" ? "#47ad1d " : "black"}
                      stroke-width="1.5"
                    />
                    <path
                      d="M15.8999 7.7998V16.7998"
                      stroke={props.page == "salvexEI" ? "#47ad1d " : "black"}
                      stroke-width="1.5"
                    />
                  </svg>

                  <span
                    className={
                      props.page == "salvexEI" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                    }
                  >
                    Salvex EI
                  </span>
                  <svg
                    class="chevron-down-icon"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5.32812 7.5L10.3281 12.5L15.3281 7.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </Accordion.Header>
              <Accordion.Body className="accordion-body" onEnter={() => props.setShowMenu(true)}>
                <ul>
                  <li>
                    <a
                      href="/settings/ei-referred-listings"
                      className={
                        props.pageCat == "referredListings"
                          ? "active-menu pb-1 mt-1"
                          : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/settings/ei-referred-listings")}
                    >
                      Ei Referred Listings
                    </a>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          )}
          <Accordion.Item eventKey="5" className="accordion-item" onClick={() => history.push("/chats")}>
            <Accordion.Header className="accordion-header side-nav-accordion-header">
              <a class="accordion-button collapsed side-nav-accordion-button" href="/chats">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
                    stroke={props.page == "chats" ? "#47ad1d " : "black"}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span
                  className={
                    props.menuList == "Chats" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                  }
                  onClick={() => history.push("/chats")}
                >
                  Messages
                </span>
                {unreadCount ? (
                  <div
                    class="button-notification-count d-flex align-items-center justify-content-center"
                    style={{
                      padding: "3px 15px 3px 15px",
                      top: props.showMenu ? "13px" : "-5px",
                      right: props.showMenu ? "151px" : "15px",
                    }}
                  >
                    {unreadCount > 0 ? unreadCount : ""}
                  </div>
                ) : (
                  ""
                )}
              </a>
            </Accordion.Header>
          </Accordion.Item>
          <Accordion.Item
            eventKey="21"
            className="accordion-item dropdown"
            onClick={() => {
              if (!props.showMenu) {
                props.setShowMenu(true);
              }
              if (activeKey == "21") {
                setActiveKey("0");
              } else {
                setActiveKey("21");
              }
            }}
          >
            <Accordion.Header class="accordion-header side-nav-accordion-header">
              <div class="accordion-button collapsed side-nav-accordion-button">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.8064 7.62337L20.184 6.54328C19.6574 5.62936 18.4905 5.31408 17.5753 5.83847C17.1397 6.0951 16.6198 6.16791 16.1305 6.04084C15.6411 5.91378 15.2224 5.59727 14.9666 5.16113C14.8021 4.88391 14.7137 4.56815 14.7103 4.2458C14.7251 3.72898 14.5302 3.22816 14.1698 2.85743C13.8094 2.48669 13.3143 2.27762 12.7973 2.27783H11.5433C11.0367 2.27783 10.5511 2.47967 10.1938 2.8387C9.83644 3.19773 9.63693 3.68435 9.63937 4.19088C9.62435 5.23668 8.77224 6.07657 7.72632 6.07646C7.40397 6.07311 7.08821 5.9847 6.81099 5.82017C5.89582 5.29577 4.72887 5.61105 4.20229 6.52497L3.5341 7.62337C3.00817 8.53615 3.31916 9.70236 4.22975 10.2321C4.82166 10.5738 5.18629 11.2053 5.18629 11.8888C5.18629 12.5723 4.82166 13.2038 4.22975 13.5456C3.32031 14.0717 3.00898 15.2351 3.5341 16.1451L4.16568 17.2344C4.4124 17.6795 4.82636 18.0081 5.31595 18.1472C5.80554 18.2863 6.3304 18.2247 6.77438 17.9758C7.21084 17.7211 7.73094 17.6513 8.2191 17.782C8.70725 17.9126 9.12299 18.2328 9.37392 18.6714C9.53845 18.9486 9.62686 19.2644 9.63021 19.5868C9.63021 20.6433 10.4867 21.4998 11.5433 21.4998H12.7973C13.8502 21.4998 14.7053 20.6489 14.7103 19.5959C14.7079 19.0878 14.9086 18.5998 15.2679 18.2405C15.6272 17.8812 16.1152 17.6804 16.6233 17.6829C16.9449 17.6915 17.2594 17.7795 17.5387 17.9392C18.4515 18.4651 19.6177 18.1541 20.1474 17.2435L20.8064 16.1451C21.0615 15.7073 21.1315 15.1858 21.001 14.6961C20.8704 14.2065 20.55 13.7891 20.1108 13.5364C19.6715 13.2837 19.3511 12.8663 19.2206 12.3767C19.09 11.8871 19.16 11.3656 19.4151 10.9277C19.581 10.6381 19.8211 10.398 20.1108 10.2321C21.0159 9.70265 21.3262 8.54325 20.8064 7.63252V7.62337Z"
                    stroke={props.page == "mfrAndModel" ? "#47ad1d " : "black"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M12.1746 14.5249C13.6304 14.5249 14.8106 13.3448 14.8106 11.8889C14.8106 10.4331 13.6304 9.25293 12.1746 9.25293C10.7188 9.25293 9.53857 10.4331 9.53857 11.8889C9.53857 13.3448 10.7188 14.5249 12.1746 14.5249Z"
                    stroke={props.page == "mfrAndModel" ? "#47ad1d " : "black"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>

                <span
                  className={
                    props.page == "mfrAndModel" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                  }
                >
                  Settings
                </span>
                <svg
                  class="chevron-down-icon"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.32812 7.5L10.3281 12.5L15.3281 7.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </Accordion.Header>
            <Accordion.Body className="accordion-body" onEnter={() => props.setShowMenu(true)}>
              <ul>
                {isSellerListingVisible &&
                <li>
                  <a
                    href="/settings/public-seller-listings"
                    className={
                      props.pageCat == "sellerPublicListings"
                        ? "active-menu pb-1 mt-1"
                        : "deafult-black-hover-green pb-1 mt-1"
                    }
                    onClick={() => history.push("/settings/public-seller-listings")}
                  >
                    Seller on Public Listings
                  </a>
                </li>}
                {docTemplateAccess && (
                  <li>
                    <a
                      href="/settings/ncnda-edit"
                      className={
                        props.pageCat == "ncnda" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                      }
                      onClick={() => history.push("/settings/ncnda-edit")}
                    >
                      NCNDA
                    </a>
                  </li>
                )}
                <li>
                  <a
                    href="/settings/manufacturers"
                    className={props.pageCat == "mfr" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"}
                    onClick={() => history.push("/settings/manufacturers")}
                  >
                    Manufacturers
                  </a>
                </li>
                <li>
                  <a
                    href="/settings/models"
                    className={
                      props.pageCat == "model" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                    }
                    onClick={() => history.push("/settings/models")}
                  >
                    Models
                  </a>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="4"
            className="accordion-item"
            onClick={() => {
              const a = window.location.href;
              setLogOutRedirect(a);
              clearAllToken();
              window.location.assign("/signin");
            }}
          >
            <Accordion.Header className="accordion-header side-nav-accordion-header">
              <div class="accordion-button collapsed side-nav-accordion-button">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
                    stroke={props.page == "LogOut" ? "#47ad1d" : "black"}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 17L21 12L16 7"
                    stroke={props.page == "LogOut" ? "#47ad1d" : "black"}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 12H9"
                    stroke={props.page == "LogOut" ? "#47ad1d" : "black"}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span
                  className={
                    props.menuList == "LogOut" ? "active-menu pb-1 mt-1" : "deafult-black-hover-green pb-1 mt-1"
                  }
                  // style={{ paddingBottom: "2px", marginTop: "2px" }}
                  onClick={() => {
                    const a = window.location.href;
                    setLogOutRedirect(a);
                    clearAllToken();
                    window.location.assign("/signin");
                  }}
                >
                  Log Out
                </span>
              </div>
            </Accordion.Header>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAllUnreadCount: (callback) => dispatch(getAllUnreadCount(callback)),
  getAllReminderQuestions: (callback) => dispatch(getAllReminderQuestions(callback)),
});

const mapStateToProps = (state) => ({
  questionDetails: questionDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
