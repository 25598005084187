import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const FilterModal = (props) => {
  const [filters, setFilters] = useState({ keyword: "", action: "" });

  const applyFilter = () => {
    const value = { ...props?.params };
    value.keyword = filters.keyword;
    value.action = filters.action;
    props?.setParams(value);
  };

  useEffect(() => {
    if (props?.show) {
      const value = { ...filters };
      value.keyword = props?.params.keyword;
      value.action = props?.params.action;
      setFilters(value);
    }
  }, [props?.show]);

  return (
    <Offcanvas
      show={props.show}
      onHide={() => props.onHide()}
      placement="end"
      className="offcanvas offcanvas-end custom-offcanvas-sm "
      backdropClassName="upload-images-backdrop"
      tabindex="-1"
      id="userFilter"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header aviation-filter-fix">
        <h5 class="offcanvas-title">Filters</h5>
        <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body co-body">
        <div class="form-group">
          <label for="keyword" class="form-label">
            Keyword
          </label>
          <input
            type="text"
            class="form-control"
            id="auction-search"
            value={filters?.keyword}
            onChange={(e) => {
              const data = { ...filters };
              data.keyword = e.target.value;
              setFilters(data);
            }}
          />
        </div>
      </Offcanvas.Body>
      <div class="offcanvas-footer">
        <a
          href="javascript:void(0)"
          class={`btn btn-default btn-block ${props.loading && "disabled"}`}
          onClick={() => applyFilter()}
        >
          Apply Filters
        </a>
      </div>
    </Offcanvas>
  );
};

export default FilterModal;
