import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/header";
import LoadingBar from "react-top-loading-bar";
import { connect } from "react-redux";
import { getPublicSellerListings, primarySellerData, updateSellerDisplayRequest } from "../../store/primarySeller";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import _ from "lodash";
import SellerPublicListingsTable from "../../components/settings/sellerPublicListings/sellerPublicListingsTable";
import FilterModal from "../../components/settings/sellerPublicListings/modals/filterModal";

const SellerPublicListings = (props) => {
  const ref = useRef(null);

  const data = props?.primarySellerData?.data;
  const totalCount = props?.primarySellerData?.totalCount;
  const totalPages = Math.ceil(totalCount / 100);

  const [params, setParams] = useState({ limit: 100, page: 1, keyword: "", action: "" });
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loadingChange, setLoadingChange] = useState(false);

  const [showFilter, setShowFilter] = useState(false);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const getSellerListings = () => {
    ref?.current?.continuousStart();
    props?.getPublicSellerListings(params, (res) => {
      if (res?.status === 200) {
        setLoading(false);
        ref?.current?.complete();
      } else {
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message || "Something went wrong"} />);
      }
    });
  };

  useEffect(() => {
    getSellerListings();
  }, [params]);

  const updateAction = (action) => {
    ref?.current?.continuousStart();
    setLoadingChange(true);
    props?.updateSellerDisplayRequest(
      {
        auction_id: `${selectedItem?.auction_id}`,
        action: action,
        request_id: selectedItem?.id,
      },
      (res) => {
        ref?.current?.complete();
        setLoadingChange(false);

        if (res?.status === 200) {
          getSellerListings();
        } else {
          <AlertError message={res?.data?.message || "Something went wrong"} />;
        }
      },
    );
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <SellerPublicListingsTable
        data={data}
        toggleFilter={toggleFilter}
        params={params}
        setParams={setParams}
        loading={loading}
        totalCount={totalCount}
        updateAction={updateAction}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        loadingChange={loadingChange}
        totalPages={totalPages}
      />
      <FilterModal show={showFilter} onHide={toggleFilter} params={params} setParams={setParams} loading={loading} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPublicSellerListings: (params, callback) => dispatch(getPublicSellerListings(params, callback)),
  updateSellerDisplayRequest: (data, callback) => dispatch(updateSellerDisplayRequest(data, callback)),
});

const mapStateToProps = (state) => ({
  primarySellerData: primarySellerData(state)?.publicSellerListings,
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SellerPublicListings));
